import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";

const AddTable = ({ startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleAddTable = () => {
    setShowOptions(true);
  };

  // Funciton
  function extractTotalsWithGroupName(data) {
    const totalsList = [];
  
    if (!data || !data.metrics || !Array.isArray(data.metrics)) {
      return totalsList; // Handle cases where data is missing or invalid
    }
  
    for (const group of data.metrics) {
      const groupName = group.group_name;
      const metrics = group.metrics;
  
      if (Array.isArray(metrics)) {
        if (
          metrics.length > 1 &&
          typeof metrics[metrics.length - 1] === 'object' &&
          metrics[metrics.length - 1] !== null &&
          'totals' in metrics[metrics.length - 1]
        ) {
          const totals = metrics[metrics.length - 1].totals;
          totals.group_name = groupName;
          totalsList.push(totals);
        }
      }
    }
  
    return totalsList;
  }
  // Function

  const fetchGroupNames = async () => {
    try {
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/fetch-groups",
        {
          email: localStorage.getItem("email"),
          customer_id: localStorage.getItem("customer_id"),
        }
      );
      const groups = response.data.groups.map((group) => ({
        id: group.group_id,
        name: group.group_name,
      }));
      setGroupNames(groups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const fetchData = async () => {
    if (!startDate || !endDate) {
      alert("Please select a valid date range.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.confidanto.com/get-grouped-campaign-metrics",
        {
          group_names: selectedGroups,
          customer_id: localStorage.getItem("customer_id"),
          email: localStorage.getItem("email"),
          start_date: startDate,
          end_date: endDate,
        }
      );

      const metrics = response.data.metrics?.[0]?.metrics || [];

      let groupsData = extractTotalsWithGroupName(response.data)

      console.log("Custom Table; metrics",metrics, groupsData)
      setData(groupsData);
      calculateTotals(groupsData);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to load data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = (campaignData) => {
    const totals = campaignData.reduce(
      (acc, campaign) => {
        acc.clicks += campaign.clicks || 0;
        acc.conversions += campaign.conversions || 0;
        acc.ctr += parseFloat(campaign.ctr) || 0;
        acc.cost += campaign.cost || 0;
        acc.impressions += campaign.impressions || 0;
        acc.average_cost += campaign.average_cost || 0;
        acc.average_cpc += campaign.average_cpc || 0;
        acc.invalid_clicks += campaign.invalid_clicks || 0;
        acc.top_impression_percentage += parseFloat(campaign.top_impression_percentage) || 0;
        return acc;
      },
      {
        clicks: 0,
        conversions: 0,
        ctr: 0,
        cost: 0,
        impressions: 0,
        average_cost: 0,
        average_cpc: 0,
        invalid_clicks: 0,
        top_impression_percentage: 0,
      }
    );
  
    const length = campaignData.length || 1;
    totals.ctr /= length;
    totals.top_impression_percentage /= length;
  
    setTotals(totals);
  };

  useEffect(() => {
    if (selectedOption === "group") {
      fetchGroupNames();
    }
  }, [selectedOption]);

  const handleGroupChange = (event) => {
    setSelectedGroups(event.target.value);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-fit">
      {!showOptions && (
        <button
          onClick={handleAddTable}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded shadow-md"
        >
          Add Table
        </button>
      )}

      {showOptions && (
        <div className="flex flex-col items-start gap-6">
          <FormControl className="w-full">
            <InputLabel>Select Option</InputLabel>
            <Select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="w-full border border-gray-300 rounded-md bg-white"
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              <MenuItem value="group">Group</MenuItem>
              <MenuItem value="device">Device</MenuItem>
              <MenuItem value="audience">Audience</MenuItem>
            </Select>
          </FormControl>

          {selectedOption === "group" && (
            <div className="w-full p-6 border border-gray-300 rounded shadow-sm bg-white">
              <h3 className="text-gray-800 font-semibold mb-4">
                Select Groups
              </h3>
              <FormControl className="w-full">
                <InputLabel>Groups</InputLabel>
                <Select
                  multiple
                  value={selectedGroups}
                  onChange={handleGroupChange}
                  renderValue={(selected) => selected.join(", ")}
                  className="border border-gray-300 rounded-md bg-white"
                >
                  {groupNames.map((group) => (
                    <MenuItem key={group.id} value={group.name}>
                      <Checkbox
                        checked={selectedGroups.indexOf(group.name) > -1}
                      />
                      <ListItemText primary={group.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedGroups.length > 0 && (
                <button
                  onClick={fetchData}
                  className="mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded shadow-md"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Create"}
                </button>
              )}
            </div>
          )}

          {data.length > 0 && (
            <div className="mt-6 overflow-x-auto">
              <table className="min-w-full bg-white border rounded shadow-md">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Group Name
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Clicks
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Conv.
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      CTR
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Cost
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Impr
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      avg.cost
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      avg.cpc
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      Invalid Clicks
                    </th>
                    <th className="px-6 py-3 border text-left font-medium text-gray-700">
                      impr.(Top) %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedGroups.map((groupName, index) => {
                    const campaign =
                      data.find(
                        (campaign) => campaign.group_name === groupName
                      ) || {}; // Default to empty object if no match

                    if (!campaign.group_name) {
                      console.warn(`No data found for group: ${groupName}`);
                    }
                    return (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="px-6 py-3 border text-gray-800">
                          {groupName}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.clicks || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.conversions || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.ctr || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.cost || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.impressions || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.average_cost || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.average_cpc || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.invalid_clicks || 0}
                        </td>
                        <td className="px-6 py-3 border text-gray-800">
                          {campaign.top_impression_percentage || 0}
                        </td>
                      </tr>
                    );
                  })}
                  {totals && (
                    <tr className="bg-gray-100 font-bold">
                      <td className="px-6 py-3 border">Total</td>
                      <td className="px-6 py-3 border">{totals.clicks}</td>
                      <td className="px-6 py-3 border">{totals.conversions}</td>
                      <td className="px-6 py-3 border">
                        {totals.ctr.toFixed(2)}%
                      </td>
                      <td className="px-6 py-3 border">{totals.cost}</td>
                      <td className="px-6 py-3 border">{totals.impressions}</td>
                      <td className="px-6 py-3 border">
                        {totals.average_cost.toFixed(2)}
                      </td>
                      <td className="px-6 py-3 border">
                        {totals.average_cpc.toFixed(2)}
                      </td>
                      <td className="px-6 py-3 border">
                        {totals.invalid_clicks}
                      </td>
                      <td className="px-6 py-3 border">
                        {totals.top_impression_percentage.toFixed(2)}%
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddTable;
