import React, { useState, useEffect, useRef } from "react";
import { FaFilter, FaSearch, FaColumns, FaExpand, FaCompress, FaGripLines,   FaLayerGroup,} from "react-icons/fa";
import LoadingAnimation from "../components/LoadingAnimation";
import { MdOutlineFileDownload } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import { format, isYesterday, isToday } from "date-fns";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import Switcher7 from "./Tools/Switcher";
import axios from "axios";


import { Autocomplete, TextField, Button, Box } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Link } from "react-router-dom"; 
import { RiDeleteBin6Line } from "react-icons/ri";
import { Category } from "@mui/icons-material";
import { IoCodeSharp } from "react-icons/io5";

const Searchkeywords = () => {
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [columns, setColumns] = useState([
  {id:"17", title:"Keyword Text",       key:"keyword_text",       visible:true, category:"Recommended"},
  {id:"18", title:"Match Type",         key:"match_type",         visible:true, category:"Recommended"},
  {id:"19", title:"Status",             key:"status",             visible:true, category:"Recommended"},
  {id:"4", title:"Campaign Name",      key:"campaign_name",      visible:true, category:"Recommended"},
  {id:"5", title:"Clicks",             key:"clicks",             visible:true, category:"Metric"},
  {id:"6", title:"Conv rate",    key:"conversion_rate",    visible:true, category:"Metric"},
  {id:"7", title:"Conversions",        key:"conversions",        visible:true, category:"Metric"},
  {id:"8", title:"Cost/conv",key:"cost_per_conv",visible:true, category:"Metric"},
  {id:"9", title:"Costs",              key:"costs",              visible:true, category:"Metric"},
  {id:"10", title:"Ctr",                key:"ctr",                visible:true, category:"Metric"},
  {id:"2", title:"Avg Cpc",        key:"average_cpc",        visible:true, category:"Metric"},
  {id:"11", title:"Final urls",         key:"final_urls",         visible:true, category:"Recommended"},
  {id:"12", title:"Impressions",        key:"impressions",        visible:true, category:"Metric"},
  {id:"13", title:"Interaction rate",   key:"interaction_rate",   visible:true, category:"Metric"},
  {id:"14", title:"Interactions",       key:"interactions",       visible:true, category:"Metric"},
  {id:"15", title:"Negative",        key:"is_negative",        visible:true, category:"Metric"},
  {id:"0", title:"id",        key:"ad_group_id",        visible:false, category:"Recommended"},
  {id:"1", title:"Name",      key:"ad_group_name",      visible:false, category:"Recommended"},
  {id:"3", title:"Campaign Id",        key:"campaign_id",        visible:false, category:"Recommended"},
  {id:"16", title:"Keyword id",         key:"keyword_id",         visible:false, category:"Recommended"},

  ]);
  
  const [backupColumns, setBackupColumns] = useState(columns)
  const [customColumns, setCustomColumns] = useState([])
  const [NoDefaultDataColumns, setNoDefaultDataColumns] = useState(
    [
    "average_cpc_percent_diff",
    "clicks_percent_diff",
    "conversion_rate_percent_diff",
    "conversions_percent_diff",
    "cost_per_conversion_percent_diff",
    "costs_percent_diff",
    "ctr_percent_diff",
    "impressions_percent_diff",
    "interaction_rate_percent_diff",
    
    "status"]
  )
  const [PercentColumns, SetPercentColumns] = useState(
    [
      "average_cpc_percent_diff",
      "clicks_percent_diff",
      "conversion_rate_percent_diff",
      "conversions_percent_diff",
      "cost_per_conversion_percent_diff",
      "costs_percent_diff",
      "ctr_percent_diff",
      "impressions_percent_diff",
      "interaction_rate_percent_diff"
    ]
  )

  const [tableVisible, setTableVisible] = useState(true);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [data, setData] = useState([]);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const [total, setTotal] = useState({})
 
  let today = new Date();
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  
  let d = new Date();
  let year = d.getFullYear()
  let month = d.getMonth();

  const [state, setState] = useState([
    {
      startDate: firstDayOfMonth,
      endDate: today,
      key: 'selection',
    },
  ]);

  
  const fetchGroups = async () => {
    try {
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/fetch-groups",
        {
          email: localStorage.getItem("email"),
          customer_id: localStorage.getItem("customer_id") == "Not Connected"||localStorage.getItem("customer_id") == null
            ? "4643036315"
            : localStorage.getItem("customer_id"),
        }
      );

      if (response.data.groups) {
        const groupsData = response.data.groups;
        setSavedGroups(groupsData);

        // Extract group IDs
        const names = groupsData.map((group) => group.group_name);
        setGroupNames(names);
      }
    } catch (error) {
      console.error("Error fetching campaign groups:", error);
    }
  };

  const [isGroupDropdownVisible, setGroupDropdownVisible] = useState(false);
  const [savedGroups, setSavedGroups] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [totalShow,setTotalShow] = useState(true)
  const [selectedGroupIds, SetSelectedGroupIds] = useState([]);


  const handleGroupCheckboxChange = (e) => {
    let checkedId = e.target.value;
    console.log(checkedId,selectedGroupIds )
    if (e.target.checked) {
      SetSelectedGroupIds([...selectedGroupIds, checkedId]);
    } else {
      SetSelectedGroupIds(selectedGroupIds.filter((id) => id !== checkedId));
    }
  };

  function FilterClickButton(){
    console.log("SOmething");
    console.log("selectedGroupIds",selectedGroupIds)

    
    axios.post("https://api.confidanto.com/keyword-grouped-metrics",{
        customer_id: localStorage.getItem("customer_id") == "Not Connected"
        ? "4643036315"
        : localStorage.getItem("customer_id"),
        email: localStorage.getItem("email"),
        group_ids: selectedGroupIds,
        start_date:  format(state[0].startDate, "yyyy-MM-dd"),
        end_date: format(state[0].endDate, "yyyy-MM-dd") 
    }).then(res=>{

      // cols
      let groupColumns = [
        {id:0, title:"Group Name", key:"group_name",visible:true},
        {id:0, title:"Avg Cpc", key:"average_cpc",visible:true},
        {id:0, title:"Costs", key:"costs",visible:true},
        {id:0, title:"Ctr", key:"ctr",visible:true},
        {id:0, title:"Clicks", key:"clicks",visible:true},
        {id:0, title:"Impressions", key:"impressions",visible:true},
        {id:0, title:"Conversion Rate", key:"conversion_rate",visible:true},
        {id:0, title:"Conversions", key:"conversions",visible:true},
        {id:0, title:"Cost/Conv", key:"cost_per_conv",visible:true},
        // {id:0, title:"Avg Cpm", key:"average_cpm",visible:true},
      ]
      // data


      let tempData = res.data 

      let groupData = tempData.map(item=>{

        let obj = {
        "group_name":item.group_name,
        "average_cpc": item.group_totals.average_cpc,
        "clicks": item.group_totals.clicks,
        "conversion_rate": item.group_totals.conversion_rate,
        "conversions": item.group_totals.conversions,
        "cost_per_conv": item.group_totals.cost_per_conv,
        "costs": item.group_totals.costs,
        "ctr": item.group_totals.ctr,
        "impressions": item.group_totals.impressions,
        // "average_cpm": item.group_totals.average_cpm,
        }
        console.log(item);

        return obj

      })

      
      Object.keys(total).forEach(

        (key) => {delete total[key]
        // console.log(key,total[key])
        // console.log(key,total[key])
      }
      );
      
      Object.keys(total).forEach(

        (key) => {delete total[key]
        // console.log(key,total[key])
        // console.log(key,total[key])
      }
      );

      setData(groupData)
      setColumns(groupColumns)
      setTotal({})
      setTotalShow(false)
      setTotalShow(true)
      setGroupDropdownVisible(false)
      SetSelectedGroupIds([])

      console.log("TOtla: ",tempData);

    
    })
    .catch(err=>{
      console.log(err);
    })

  }
  
  
  function handleSelectDateRanges(ranges){
    let key = Object.keys(ranges)[0] //curr key
    let values = Object.values(ranges)[0] //curr obj vals

    // Set Selection Date
    // if(key == "selection"){
    for (const i of state){
      if(i.key == key){
        i.startDate = values.startDate
        i.endDate = values.endDate
      }
    }
    setState(state)
  }

    
  function compareDateRanges(){
    let tempStates = state


    if(state.length > 1){

      setState([tempStates[0]])
      
    }else{

      let newState = {
        startDate: new Date(year, month - 1, 1),
        endDate: new Date(year, month, 0),
        key: "compare",
      }

  
      setState([...tempStates,newState])

    }


    console.log(state)
  }


  useEffect(() => {

    axios.post("https://api.confidanto.com/custom_columns/get-custom-columns", {
      email: localStorage.getItem("email")
    })
    .then((res)=>{
      let resData = res.data.data
      let temp = resData.map(i=>{
        
        let obj = {
          id:i.id,
          title:i.custom_column,
          key:i.custom_column,
          visible:false,
          category:"Custom Columns"
        }
        console.log("ci",i)

        return obj
      })
      console.log("temp: ",temp)

      setCustomColumns(temp)
      setColumns([...columns,...temp])
      setBackupColumns([...columns,...temp])
      console.log("custom columns: ",customColumns, columns)
    })
   
    fetch("https://api.confidanto.com/get_keyword_datewise_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: 4643036315,
        email: localStorage.getItem("email")
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data)
      
      })
      .catch((error) => console.error("Error fetching data:", error));

      // setColumns([...columns,...customColumns])
      // console.log("custom columns: ",customColumns, columns)

      fetchGroups()
    
  }, []);

  
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return; // If the item was dropped outside the list

    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(source.index, 1);
    updatedColumns.splice(destination.index, 0, movedColumn);
    setColumns(updatedColumns);
  };
  const [showCustomColumnForm, setShowCustomColumnForm] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);

  const uniqueCategories = Array.from(new Set(columns.map(col => col.category)));

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };
  
  const [customColumn, setCustomColumn] = useState({
    name: "",
    selectedColumns: [],
    selectedFormula: "",
    manualFormula: "",
  });
  
  const addCustomColumn = () => {
    const { name, selectedColumns, selectedFormula } = customColumn;
    if (!name || selectedColumns.length < 1) return;

    const newColumnData = data.map((row) => {
      let result = 0;

      if (selectedFormula === "sum") {
        result = selectedColumns.reduce((sum, colKey) => {
          const value = parseFloat(row[colKey]) || 0;
          return sum + value;
        }, 0);
      } else if (selectedFormula === "average") {
        const total = selectedColumns.reduce((sum, colKey) => {
          const value = parseFloat(row[colKey]) || 0;
          return sum + value;
        }, 0);
        result = total / selectedColumns.length;
      }
      // Additional operations like multiplication or division can be added here
      Object.keys(total).forEach(key => delete total[key]);

      return { ...row, [name]: result };


    });

    setColumns([...columns, { title: name, key: name }]);
    setData(newColumnData);
    setShowCustomColumnForm(false);
    setCustomColumn({ name: "", selectedColumns: [], selectedFormula: "", manualFormula: "" });
  };
  // const [customColumns, setCustomColumns] = useState([])
  
  const handleCustomColumnChange = (e) => {
    const { name, value } = e.target;
    setCustomColumn((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleColumnSelect = (event, newValue) => {
    setCustomColumn((prev) => ({
      ...prev,
      selectedColumns: newValue,
    }));
  };
  
  const deleteCustomColumn = (id, name) => {
    let con = window.confirm(`Delete ${name} Column?`)
    

    if(con){
      axios.post("https://api.confidanto.com/custom_columns/delete-custom-column",{
        email:localStorage.getItem("email"),
        id:id
      }).then(res=>{
        // console.log(res.data);
        let newColumns = columns.filter(col=>col.id != id)
        setColumns(newColumns)
        // console.log("old Cols", columns);
        // console.log("new Cols", newColumns);
        // setRandom(Math.random())
      }).catch(error=>{
        console.log(error);
      })
    }
    // console.log(id, name)
  }
  
  const ColumnItem = ({ column, index, toggleVisibility, category }) => {
    return (
      <div className="flex flex-row items-center justify-between  p-2 mb-1 rounded cursor-pointer bg-white shadow-sm hover:bg-slate-100">
        <div className="">
          <input
            type="checkbox"
            checked={column.visible}
            onChange={() => toggleVisibility(column.key)}
            className="mr-2"
            disabled={column.locked}
          />
          <span>{column.title}</span>
        </div>
        {category == "Custom Columns" && <>
        <button 
        onClick={()=>{deleteCustomColumn(column.id,column.title)}}>
          <RiDeleteBin6Line />
        </button>
        </>}
      </div>
    );
  };

  const downloadData = (format) => {
    const visibleColumns = columns.filter((col) => col.visible);
    const headers = visibleColumns.map((col) => col.title);
    const rows = data.map((item) => visibleColumns.map((col) => item[col.key]));

    if (format === "pdf") {
      const doc = new jsPDF();
      autoTable(doc, { head: [headers], body: rows });
      doc.save("data.pdf");
    } else if (format === "csv" || format === "excel") {
      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      if (format === "csv") {
        XLSX.writeFile(wb, "data.csv");
      } else {
        XLSX.writeFile(wb, "data.xlsx");
      }
    } else if (format === "xml") {
      const xmlContent = `
        <root>
          ${data
          .map(
            (item) => `
            <row>
              ${visibleColumns
                .map((col) => `<${col.key}>${item[col.key]}</${col.key}>`)
                .join("")}
            </row>
          `
          )
          .join("")}
        </root>
      `;
      const blob = new Blob([xmlContent], { type: "application/xml" });
      FileSaver.saveAs(blob, "data.xml");
    } else if (format === "google_sheets") {
      const csvContent = [
        headers.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = `https://docs.google.com/spreadsheets/d/your-sheet-id/edit?usp=sharing`;
      window.open(url, "_blank");
      FileSaver.saveAs(blob, "data.csv");
    }

    setShowDownloadOptions(false);
  };
  const applyChanges = () => {
    setShowColumnsMenu(false);
    setTableVisible(true);
  };
  
  const datePickerRef = useRef(null);
  
  let SegmentRef = useRef(null)
  let FilterRef = useRef(null)
  let CategoryRef = useRef(null)
  let DownloadRef = useRef(null)
  let ColumnRef = useRef(null)

  useEffect(() => {
    // Handle clicks outside of the datepicker
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }

      
      if (
        FilterRef.current &&
        !FilterRef.current.contains(event.target)
      ) {
        // // Filter
        // setShowFilterDropdown(false)
        // setShowFilterDropdown(false);

        // hide if click on drop so off here
      }

      
      if (
        CategoryRef.current &&
        !CategoryRef.current.contains(event.target)
      ) {
        // // Category
        // setGroupDropdownVisible(false)
        setGroupDropdownVisible(false);
      }


      
      if (
        DownloadRef.current &&
        !DownloadRef.current.contains(event.target)
      ) {
        // // Download 
        // setShowDownloadOptions(false)
        setShowDownloadOptions(false);
      }
      
      if (
        ColumnRef.current &&
        !ColumnRef.current.contains(event.target)
      ) {
        // // Columns
        // setShowColumnsMenu(false)
        setShowColumnsMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  function closeModalBoxes(type){

    setShowColumnsMenu(false)
    setShowDownloadOptions(false)
    setGroupDropdownVisible(false)
    // setShowFilterMenu(false)
    setFilterBoxToggle(false)
    // setIsGroupListVisible(false)
    setShowDatePicker(false)

    
    
    if(type == "Date"){
      setShowDatePicker(!showDatePicker)
    }else if(type == "Segment"){
      // setIsGroupListVisible(!isGroupListVisible)
    }else if(type == "Filter"){
      // setShowFilterMenu(!showFilterMenu)
      setFilterBoxToggle(!filterBoxToggle)
  
    }else if(type == "Category"){
      setGroupDropdownVisible(!isGroupDropdownVisible)
    }else if(type == "Download"){
      setShowDownloadOptions(!showDownloadOptions)
    }else if(type == "Column"){
      setShowColumnsMenu(!showColumnsMenu)
    }
  }

  const cancelChanges = () => {
    setShowColumnsMenu(false);
    setTableVisible(true);
  };
  const formatDateDisplay = (date) => {
    if (isToday(date)) {
      return `Today ${format(date, "MMM dd, yyyy")}`;
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, "MMM dd, yyyy")}`;
    } else {
      return format(date, "MMM dd, yyyy");
    }
  };
  const formatButtonLabel = () => {
    const { startDate, endDate } = state[0];

    // Check if start and end dates are in the same month and year
    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      // Format as 'Nov 1 - 5, 2024'
      return `${format(startDate, 'MMM d')} - ${format(endDate, 'd, yyyy')}`;
    } else {
      // Format as 'Nov 1, 2024 - Dec 5, 2024' if they differ
      return `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`;
    }
  };
  const fetchAdGroupData = () => {
    

    if(state.length > 1){

      
      const CurrstartDate = format(state[0].startDate, "yyyy-MM-dd");
      const CurrendDate = format(state[0].endDate, "yyyy-MM-dd");
      const PrevstartDate = format(state[1].startDate, "yyyy-MM-dd");
      const PrevendDate = format(state[1].endDate, "yyyy-MM-dd");


      console.log(CurrstartDate,
        CurrendDate,
        PrevstartDate,
        PrevendDate);

        const RequestBody = {
          "customer_id": "4643036315",
          "start_date": CurrstartDate, // curr date
          "end_date": CurrendDate,
          "previous_start_date": PrevstartDate,  //prev date
          "previous_end_date": PrevendDate,
          "email":localStorage.getItem("email")
        }
        
        axios.post("https://api.confidanto.com/get_keyword_datewise_data",RequestBody)
        .then((res)=>{
          console.log("IUYREXRDCFV",res.data)
          setData(res.data); // Update the table data
          setShowDatePicker(false);

          let newColumns = [
            {id:"17",   title:"Keyword Text",       key:"keyword_text",       visible:true, category:"Recommended"},
            {id:"18",   title:"Match Type",         key:"match_type",         visible:true, category:"Recommended"},
            {id:"19",   title:"Status",             key:"status",             visible:true, category:"Recommended"},
            {id:"4",    title:"Campaign Name",      key:"campaign_name",      visible:true, category:"Recommended"},
            {id:"5",    title:"Clicks",             key:"clicks",             visible:true, category:"Performance"},
            {id:"20",   title:"Clicks  ", key:"clicks_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"6",    title:"Conversion rate",    key:"conversion_rate",    visible:true, category:"Performance"},
            {id:"21",   title:"Conversion rate  ", key:"conversion_rate_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"7",    title:"Conversions",        key:"conversions",        visible:true, category:"Performance"},
            {id:"22",   title:"Conversions  ", key:"conversions_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"8",    title:"Cost/Conv",key:"cost_per_conversion",visible:true, category:"Performance"},
            {id:"23",   title:"Cost/Conv  ", key:"cost_per_conversion_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"9",    title:"Cost",              key:"costs",              visible:true, category:"Performance"},
            {id:"24",   title:"Cost  ", key:"costs_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"10",   title:"Ctr",                key:"ctr",                visible:true, category:"Performance"},
            {id:"25",   title:"Ctr  ", key:"ctr_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"2",    title:"Avg Cpc",        key:"average_cpc",        visible:true, category:"Performance"},
            {id:"28",   title:"Avg Cpc  ", key:"average_cpc_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"11",   title:"Final_urls",         key:"final_urls",         visible:true, category:"Recommended"},
            {id:"12",   title:"Impressions",        key:"impressions",        visible:true, category:"Performance"},
            {id:"26",   title:"Impressions  ", key:"impressions_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"13",   title:"Interaction rate",   key:"interaction_rate",   visible:true, category:"Performance"},
            {id:"27",   title:"Interaction rate  ", key:"interaction_rate_percent_diff", visible:true, category:"Performance", percentage_diff:true},
            {id:"14",   title:"Interactions",       key:"interactions",       visible:true, category:"Performance"},
            {id:"15",   title:"Negative",        key:"is_negative",        visible:true, category:"Recommended"},
            {id:"29",   title:"currency_code", key:"currency_code", visible:true, category:"Recommended"},
            {id:"0",    title:"id",        key:"ad_group_id",        visible:false, category:"Recommended"},
            {id:"1",    title:"Name",      key:"ad_group_name",      visible:false, category:"Recommended"},
            {id:"16",   title:"Keyword id",         key:"keyword_id",         visible:false, category:"Recommended"},
            {id:"3",    title:"Campaign Id",        key:"campaign_id",        visible:false, category:"Recommended"},
            ]

          Object.keys(total).forEach(key => delete total[key]);
          setColumns(newColumns)
        })

    }else{
      const startDate = format(state[0].startDate, "yyyy-MM-dd");
      const endDate =   format(state[0].endDate, "yyyy-MM-dd");

      let requestBody = {
        customer_id: 4643036315,
        email: localStorage.getItem("email")
      };

      if (startDate === endDate) {
        // Single date request
        requestBody = { ...requestBody, single_date: startDate };
      } else {
        // Custom date range request
        requestBody = { ...requestBody, start_date: startDate, end_date: endDate };
      }
      // fetch("https://api.confidanto.com/get-datewise-ad-groups-data", {
      fetch("https://api.confidanto.com/get_keyword_datewise_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log("Fetched data:", responseData); // Log response in console
          setData(responseData); // Update the table data
          setColumns(backupColumns)

          
          Object.keys(total).forEach(

            (key) => {delete total[key]
            // console.log(key,total[key])
            // console.log(key,total[key])
          })
          
          setTotal({})

          setTotalShow(false)
          setTotalShow(true)
          setShowDatePicker(false);
        })
        .catch((error) => {
          console.error("Error fetching ad group data:", error);
        });
    };

  }

    // Fetch data based on selected date range
  const openColumnsMenu = () => {
    console.log("col tap", columns, customColumns)
    setShowColumnsMenu(true);
    setTableVisible(false);
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleColumnVisibility = (key) => {
    setColumns(
      columns.map((col) =>
        col.key === key && !col.locked ? { ...col, visible: !col.visible } : col
      )
    );
  };

  

  // 404 Error
  let filterButtonRef = useRef(null)
  const [filterBoxToggle,setFilterBoxToggle] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const [showCheck404Data, setShowCheck404Data] = useState(false);
  const [check404Data, setCheck404Data] = useState([]);
  const [error, setError] = useState(null);


  
  const toggleFilterBox = (e) =>{
    setFilterBoxToggle(!filterBoxToggle)
  }

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => {
      // Toggle the checked state
      const newCheckedState = !prevChecked;

      // Update the visibility based on the new state
      setShowCheck404Data(newCheckedState);
      setTableVisible(!newCheckedState);  

      // Return the new checked state
      return newCheckedState;
    });
  };

  useEffect(() => {
    const fetch404Data = async () => {
      try {
        console.log(data);
        if (data.length === 0) return; // No data to process

        const response = await fetch(
          "https://api.confidanto.com/check-ads-404",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          const errorDetails = await response.text(); // Get more details on the error
          throw new Error(
            `HTTP error! status: ${response.status}. Details: ${errorDetails}`
          );
        }

        const result = await response.json();
        console.log(result);
        // Filter the data to keep only those with Page status 404
        
        // console.log("filteredData");
        // console.log(filteredData);
        const filteredData = result.filter(
          (item) => item["Page status"] === 404
        );
        // console.log(filteredData);


        // console.log(filteredData.length);

        // console.log("JNHGVFCDXSZDFGVHBJN");

        // const coombinedData = filteredData.map((item)=>{
        //   console.log();
        // })

        // console.log(coombinedData);
        // Combine the Final URLs, Descriptions, and Headlines into the Headlines field with styling
        const combinedData = filteredData.map((item) => ({
          ...item,
          headlines: [
            ...item.headlines.map(
              (headline) =>
                `<span class="text-blue-800 cursor-pointer"> ${headline} |</span> `
            ),
            ...item.final_urls.map(
              (url) =>
                `<br/> <span class="text-green-500 cursor-pointer"> ${url}</span> <br/>`
            ),
            ...item.descriptions.map(
              (description) =>
                `<span class="cursor-pointer"> ${description} </span>`
            ),
          ].join(" "),
        }));

        // console.log("COMBINED DATA")
        // console.log(combinedData);


        setCheck404Data(combinedData);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching data:", error);
      }
    };

    fetch404Data();
  }, [data]);

  const transformPrimaryStatus = (primary_status) => {
    const words = primary_status.split("_");
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  
  // Asc Desc Buttons 
  // true=Asc, False = Desc
  const [currentOrderType, setCurrentOrderType] = useState(true)
  const [currentOrderVariable, setCurrentOrderVariable] = useState(null)

  const toggleOrderType = () => {
    setCurrentOrderType(!currentOrderType)
  }
  const toggleCurrentOrderVariable = (type) => {
    setCurrentOrderVariable(type)
  }

  const changeOrderTypeCampaign = (type) => {
    // arrow ui
    if(type != currentOrderVariable){
      toggleOrderType()
    }
    toggleOrderType()
    toggleCurrentOrderVariable(type)

    
    // sort data
    let temp = data 
    let tempDataValue = temp[0][type]
    
    console.log(type,currentOrderVariable,currentOrderType,tempDataValue,typeof( tempDataValue));
    if(currentOrderType){
      if(typeof(tempDataValue) == 'number'){
        temp = temp.sort((a,b)=> a[type] - b[type])
      }else{
        data.sort((a, b) => a[type].localeCompare(b[type]));
      }
    }else{
      if(typeof(tempDataValue) == 'number'){
        temp = temp.sort((a,b)=> b[type] - a[type])
      }else{
        data.sort((a, b) => b[type].localeCompare(a[type]));        
      }
    }
    setData(temp)
  }


  return (
    <div>
      <div 
      className={`flex h-screen bg-white ${isFullScreen
        ? "fixed top-0 left-0 w-full h-full z-50 overflow-x-scroll"
        : "mb-16"
        }`}
        >
        <main className="flex-grow p-6 overflow-y-scroll">
          <div className="flex justify-end items-center mb-4">
            {/* <div className="text-2xl font-bold text-gray-700">Search keywords</div> */}
            <div className="flex space-x-2">
            <div className="relative" ref={datePickerRef}>
              <button
                // onClick={toggleDatePicker}
                onClick={()=>{closeModalBoxes("Date")}}
                className="text-base border mr-2 border-gray-400 p-2 w-60"
              >
                {formatButtonLabel()}
              </button>
              {showDatePicker && (
                <div className="absolute z-10 mt-2 shadow-lg bg-white right-2">
                  <DateRangePicker
                    // onChange={(item) => setState([item.selection])}
                    onChange={(e)=>{handleSelectDateRanges(e)}}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    maxDate={new Date()}

                  />
                  <div className=" flex flex-row  justify-between items-center mb-2 mx-2">
                      <button
                        onClick={fetchAdGroupData} // Call API when dates are selected
                        className="bg-blue-500 text-white px-4 py-2 rounded text-center mt-2"
                        >
                        Apply
                      </button>
                        
                        <Switcher7 onToggle={()=>compareDateRanges()} flag={state.length > 1}/>
                    </div>
                
                </div>
              )}
            </div>
              <button className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
              ref={filterButtonRef}
              // onClick={toggleFilterBox}
              onClick={()=>{closeModalBoxes("Filter")}}

              >
                <FaFilter className="ml-5" /> Add filter
              </button>
              {filterBoxToggle && (
              <div
              className="absolute z-20 w-56 bg-white shadow-lg rounded-lg mt-2 p-4 border border-gray-200"
                style={{
                  top:
                    filterButtonRef.current?.offsetTop +
                    filterButtonRef.current?.offsetHeight,
                  left: filterButtonRef.current?.offsetLeft,
                }}

                ref={FilterRef}
                >
                  <div className="mt-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="form-checkbox"
                    />
                    <span>Check 404 Error</span>
                  </label>
                </div>
                </div>
              )}
              
              <div className="relative" 
              ref={CategoryRef}>
                <button
                    className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                    // onClick={() => setGroupDropdownVisible(!isGroupDropdownVisible)}
                    // onClick={()=>{toggleDropDownTabs("Category")}}
                    onClick={()=>{closeModalBoxes("Category")}}


                  >
                    <FaLayerGroup className="ml-5" />
                    Category
                  </button>
                  {isGroupDropdownVisible && (
                    <>
                      <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border w-64 border-gray-200">
                        <ul className="mx-2 h-56 overflow-y-auto">
                          <li
                            className="p-2 hover:bg-gray-100 cursor-pointer capitalize"
                            // onClick={() => handleGroupClick(null)}
                          >
                            All
                          </li>
                          {savedGroups.map((data, index) => (
                            <li
                              key={index}
                              className="p-2 hover:bg-gray-100  capitalize space-x-2"
                            >
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                name=""
                                id=""
                                value={data.group_id}
                                onChange={(e) => handleGroupCheckboxChange(e)}
                              />
                              <span>{data.group_name}</span>
                            </li>
                          ))}
                        </ul>
                        <button
                          className="bg-blue-500 text-white px-4 py-2 my-2 rounded-sm w-full"
                          onClick={()=>{FilterClickButton()}}
                        >
                          Filter
                        </button>
  
                        <Link
                          to="/google-ads/campaign-groups"
                          className="p-2 flex items-center hover:bg-gray-50 cursor-pointer justify-between w-full"
                        >
                          Edit Groups
                        </Link>
                      </div>
                    </>
                  )}
              </div>
              <div className="relative"
              ref={DownloadRef}>
              <button
                className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                // onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                onClick={()=>{closeModalBoxes("Download")}}
                
              >
                <MdOutlineFileDownload className="ml-5 " />
                Download
              </button>
              {showDownloadOptions && (
                <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border border-gray-200">
                  <button
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => downloadData("pdf")}
                  >
                    PDF
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => downloadData("csv")}
                  >
                    CSV
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => downloadData("excel")}
                  >
                    Excel
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => downloadData("xml")}
                  >
                    XML
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => downloadData("google_sheets")}
                  >
                    Google Sheets
                  </button>
                </div>
              )}
            </div>

            <button
              className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <FaCompress className="ml-5" />
              ) : (
                <FaExpand className="ml-5" />
              )}{" "}
              {isFullScreen ? "Collapse" : "Expand"}
            </button>
              
              <div className="relative" 
              ref={ColumnRef}>
                <button
                  className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                  // onClick={openColumnsMenu}
                  onClick={()=>{closeModalBoxes("Column")}}
                >
                  <FaColumns className="ml-5" /> Columns
                </button>
                {showColumnsMenu && (
                
                <div className="absolute right-0 h-max bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-max max-w-6xl border border-gray-200">
                    <div className="font-bold mb-0 w-screen max-h-full text-lg text-gray-700 flex">
                      <div className=" justify-between flex items-center border-b-1 w-[1100px] ">
                        <div className=" flex border-r-1  p-4 w-3/4">
                          <h2 className="mr-11">Modify columns</h2>
                          {/* <button
                            className="text-blue-400"
                            onClick={() => setShowCustomColumnForm(true)}
                          >
                            + Custom column
                          </button> */}
                          
                          <Link to="/custom-column" className="text-blue-400">
                          + Custom column</Link>
                        </div>
                        <div className=" justify-start">
                          <h2 className="font-bold mb-4">Your columns</h2>
                        </div>
                      </div>
                    </div>

                    {/* Show Custom Column Form if active */}
                    {showCustomColumnForm ? (
                      <Box className="absolute bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-800 max-w-3xl border border-gray-200">
                        <h2 className="font-bold mb-2 text-lg text-gray-700">Create a custom column</h2>

                        <div className="mb-2">
                          <TextField
                            label="Column Name"
                            name="name"
                            value={customColumn.name}
                            onChange={handleCustomColumnChange}
                            fullWidth
                            variant="outlined"
                          />
                        </div>

                        <div className="mb-2">
                          <Autocomplete
                            multiple
                            options={columns}
                            getOptionLabel={(option) => option.title}
                            value={customColumn.selectedColumns}
                            onChange={handleColumnSelect}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Columns to Include" variant="outlined" />
                            )}
                          />
                        </div>

                        <div className="mb-2">
                          <TextField
                            select
                            label="Select Operation"
                            name="selectedFormula"
                            value={customColumn.selectedFormula}
                            onChange={handleCustomColumnChange}
                            SelectProps={{ native: true }}
                            fullWidth
                            variant="outlined"
                          >
                            <option value="">Choose an operation</option>
                            <option value="sum">Sum</option>
                            <option value="average">Average</option>
                            {/* Add more operations as needed */}
                          </TextField>
                        </div>

                        <div className="mb-2">
                          <TextField
                            label="Manual Formula (Editable)"
                            name="manualFormula"
                            value={customColumn.manualFormula}
                            onChange={handleCustomColumnChange}
                            fullWidth
                            variant="outlined"
                            placeholder="e.g., Campaign + Clicks"
                          />
                        </div>

                        <Box display="flex" gap={2} mt={2}>
                          <Button variant="contained" color="primary" onClick={addCustomColumn}>
                            Add Column
                          </Button>
                          <Button variant="contained" color="secondary" onClick={() => setShowCustomColumnForm(false)}>
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <div className="flex ">
                          <div className="grid grid-rows-2 gap-6 max-h-screen w-3/4">
                            <div className=" space-x-3 space-y-2">
                              {uniqueCategories.map(category => (
                                <div key={category}>
                                  <div className=""
                                    onClick={() => toggleCategory(category)}>
                                    <span className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between">{category} {expandedCategory === category ? <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                                      :
                                      <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />}</span>
                                  </div>
                                  {expandedCategory === category && (
                                    <div className="grid grid-cols-3">
                                      {columns.filter(col => col.category === category).map(col => (
                                        <ColumnItem
                                          key={col.key}
                                          column={col}
                                          toggleVisibility={toggleColumnVisibility}
                                          category={col.category}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="w-max p-4">

                            <p className="text-sm text-gray-500 mb-4">Drag and drop to reorder</p>
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="columnsList">
                                {(provided) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="space-y-2 overflow-auto h-48 border rounded-md p-2"
                                  >
                                    {columns
                                      .filter((column) => column.visible) // Only show visible columns
                                      .map((column, index) => (
                                        <Draggable key={column.id} draggableId={column.id} index={index}>
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="flex items-center p-2 bg-gray-100 mb-1 rounded shadow"
                                            >
                                              <span className="flex items-center gap-4"><FaGripLines/> {column.title} </span>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                            <div className="flex items-center mt-4">
                              <input type="checkbox" id="saveColumnSet" className="mr-2" />
                              <label htmlFor="saveColumnSet" className="text-sm">Save your column set (name required)</label>
                            </div>

                          </div>
                        </div>
                        <div className="flex space-x-2 ">
                          <div className="">
                            <button
                              className=" bg-blue-500 text-white px-4  py-2 rounded hover:text-blue-600"
                              onClick={applyChanges}
                            >
                              Apply
                            </button>
                            <button
                              className="text-blue-500  px-4 py-2 rounded hover:text-blue-600"
                              onClick={cancelChanges}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                
              )}
              </div>
              
            </div>
          </div>
          {tableVisible && (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white rounded-lg overflow-y-scroll shadow-md">
                <thead>
                  <tr className="bg-gray-200  text-sm leading-normal">
                    {columns.filter(col => col.visible).map(col => (
                      <th key={col.key} className="py-3 px-6 text-left  w-auto">
                          <button className="flex flex-row justify-between items-center w-full h-full"
                            onClick={()=>{changeOrderTypeCampaign(col.key)}}
                            >
                              <h2 className=" whitespace-nowrap flex flex-row justify-between items-center font-bold">
                                {col.title}
                                {col.percentage_diff == true && 
                                  <div className="mx-2">
                                    <IoCodeSharp/>
                                  </div>
                                }
                              </h2>
                              {currentOrderVariable==col.key && 
                                <>
                                  {currentOrderType?
                                  <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                                  :
                                  <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />
                                  }
                                </>
                              }
                            </button>
                        </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                        {columns.filter(col => col.visible).map(col => (
                          <td key={col.key} className="py-3 px-6 text-left">

                            {NoDefaultDataColumns.indexOf(col.key) == -1 && (Array.isArray(item[col.key]) ? item[col.key].join(', ') : item[col.key])}

                            {PercentColumns.indexOf(col.key) != -1?
                            <>
                              {item[col.key] == null?<span>--</span>:
                              <>
                                <span>
                                  {item[col.key] +"%"}
                                </span>
                              </>}
                            </>
                            :null}

                            {col.key === "status" ? (
                                <td
                                  key={col.key}
                                  className="py-3 text-left"
                                >
                                  <div className="flex items-center">
                                    {item.status === "ENABLED" && (
                                      <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                    )}
                                    {item.status === "PAUSED" && (
                                      <div className="w-3 h-3 bg-gray-500 rounded-full mr-2"></div>
                                    )}
                                    {item.status === "REMOVED" && (
                                      <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                    )}
                                    {/* {item.status.charAt(0).toUpperCase() +
                                      item.status.slice(1).toLowerCase()} */}
                                  </div>
                                </td>
                              ) : null}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                      <td
                        colSpan={columns.filter((col) => col.visible).length}
                        className="py-3 px-6 text-center"
                      >
                        <div className="flex justify-center items-center h-40 mt-3">
                          <LoadingAnimation />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                {totalShow && <tfoot>
                    <tr className="font-bold text-gray-700 bg-gray-100 w-full">
                    {/* <td colSpan={4} className="py-3 px-6 text-left ">
                        Total: Account
                      </td>
                      <td></td>
                      <td></td>
                      <td className="py-3 px-6">{totalImpressions}</td>
                      <td className="py-3 px-6">{totalClicks}</td>
                      <td className="py-3 px-6">{totalConversions}</td>
                      <td className="py-3 px-6">${totalCost.toFixed(2)}</td>
                      <td className="py-3 px-6">${avgCpc.toFixed(2)}</td>
                      <td className="py-3 px-6">{totalCtr.toFixed(2)}%</td>
                      <td className="py-3 px-6">${totalCostPerConv.toFixed(2)}</td> */}

                    {/* <td className="px-4 py-2 ">Total</td> */}
                    {
                      ()=>{
                        console.log("gvyfctdxrdctfvygbhjk: ",total);
                        Object.keys(total).forEach(key => delete total[key]);
                        console.log("gvyfctdxrdctfvygbhjk: ",total);

                      }
                    }
                    {
                      columns.filter(col => col.visible).map(col => {
                        // //console.log("KEY",col.key)
                        total[col.key] = 0
                      })

                    }
                    {
                      data.map(d => {
                        Object.keys(d).forEach(val => {
                          Object.keys(total).forEach(totalVal => {
                            if (totalVal == val) {
                              total[val] = total[val] + d[val]
                            }
                          })
                        })
                      })
                    }

                    {
                      Object.entries(total).map((t, k) => {
                        //console.log("type",typeof(t[1]))
                        let tempval = ""
                        let ignoreColumns = ['id', 'customer_id', 'amount_micros','campaign_id','keyword_id','ad_group_id']
                        if (typeof (t[1]) == "number") {
                          if (ignoreColumns.indexOf(t[0]) == -1) {
                            tempval = numberWithCommas(t[1].toFixed(2))
                          }

                          if(PercentColumns.indexOf(t[0]) != -1){
                            tempval = String(tempval) + " %"
                          }
                        }
                        return <td className="py-3 px-6 text-left">{tempval}</td>
                      }
                      )

                    }
                    </tr>
                  </tfoot>}
              </table>
            </div>
          )}

                {showCheck404Data && (
                  <div className="overflow-x-auto">
                    {check404Data.length > 0 ? (
                      <>
                        <h1 className="text-2xl text-center p-4 font-semibold">
                          URLs with 404 Error
                        </h1>

                        <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
                          <thead className="sticky top-0 bg-gray-200 z-10">
                            <tr className="uppercase text-sm leading-normal">
                              <th className="py-3 px-6 text-left">
                                Status Code
                              </th>
                              {columns
                                .filter((col) => col.visible)
                                .map((col) => (
                                  <th
                                    key={col.key}
                                    className="py-3 px-6 text-left"
                                  >
                                    {col.title}
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="text-gray-600 text-sm font-light">
                            {check404Data.map((item, index) => (
                              <tr
                                key={index}
                                className="border-b border-gray-200 hover:bg-gray-100"
                              >
                                <td className="py-3 px-6 text-left text-red-500 font-semibold">
                                  {item["Page status"]}
                                </td>
                                {columns
                                  .filter((col) => col.visible)
                                  .map((col) => (
                                    <td
                                      key={col.key}
                                      className={`py-3 px-6 text-left ${col.key === "campaign" ||
                                          col.key === "ad_group_name"
                                          ? "text-blue-500"
                                          : ""
                                        }`}
                                    >
                                      {col.key === "headlines" ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item[col.key],
                                          }}
                                        />
                                      ) : col.key === "primary_status" ? (
                                        <span>
                                          {transformPrimaryStatus(
                                            item[col.key]
                                          )}
                                        </span>
                                      ) : col.key === "status" ? (
                                        <span className="flex items-center justify-center">
                                          <span
                                            className={`w-2.5 h-2.5 rounded-full ${item[col.key] === "ENABLED"
                                                ? "bg-green-500"
                                                : item[col.key] === "PAUSED"
                                                  ? "bg-gray-500"
                                                  : ""
                                              }`}
                                          ></span>
                                        </span>
                                      ) : Array.isArray(item[col.key]) ? (
                                        item[col.key].join(", ")
                                      ) : (
                                        item[col.key]
                                      )}
                                    </td>
                                  ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="flex justify-center items-center h-40 mt-8">
                        <h1 className="text-2xl text-center p-4">
                          No broken or 404 URLs found in your Ads.
                        </h1>
                      </div>
                    )}
                  </div>
                )}
        </main>
      </div>
    </div>
  );
};

export default Searchkeywords;