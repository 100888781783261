import axios from "axios";

const processPromptChatBox = async (text) => {
  text = text.toLowerCase();
  let response = `Your text was ${text}`;

  console.log(
    "String check: ",
    text.includes("customer"),
    text.includes("id"),
    text.includes("brand")
  );
  if (text.includes("customer") || text.includes("id")) {
    response = `Your customer id is ${
      localStorage.getItem("customer_id") == null
        ? "4643036315"
        : localStorage.getItem("customer_id")
    }`;
  } else if (text.includes("brand")) {
    await axios
      .post(
        "https://api.confidanto.com/generate-insights/generate-company-description",
        {
          prompt:
            localStorage.getItem("project_name") == null
              ? "Confidanto"
              : localStorage.getItem("project_name"),
        }
      )
      .then((res) => {
        response =
          "Your Brand name is Confidanto, Your platform provides anlytical solutions to SEM Agencies.";
        response = res.data.result;
        console.log("res.data", res.data.result);
      });
  } else if (text.includes("hi") || text.includes("hello")) {
    response = "Hi. What can I help with Today?";
  } else {
    response = "No matching response found.";
  }
  return {
    answer: response,
  };
};

export { processPromptChatBox };
