/* eslint-disable eqeqeq */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FiPlusCircle } from "react-icons/fi";
import googleAdsImage from "../assets/googleads.png";
import { format, isYesterday, isToday } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { ChatBox } from "../components";
import DonutChart from "./Charts/DonutChart";
import Bar from "./Charts/Bar";
import AddCampaignTableWeekly from "./AddCampaignTableWeekly";
import AddChart from "./ChartAdd";
import { FaColumns, FaPencilAlt } from "react-icons/fa";
import LoadingAnimation from "../components/LoadingAnimation";
import { Autocomplete, TextField, Button, Box } from "@mui/material";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdShareAlt } from "react-icons/io";
import { PiCardsBold } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter, FaExpand, FaCompress, FaGripLines } from "react-icons/fa";


import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom"; 

import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import ProjectInfo from "./SubPageFiles/ProjectDetails/ProjectInfo";
import AddTable from "./AddTable/CustomTable";

const ProjectDetails = () => {
  const [customColumn, setCustomColumn] = useState({
    name: "",
    selectedColumns: [],
    selectedFormula: "",
    manualFormula: "",
  });
  const [showCustomColumnForm, setShowCustomColumnForm] = useState(false);
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [tableVisible, setTableVisible] = useState(true);; // New state
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false); // State to manage chatbox visibility
  const [isGoogleAdsConnected, setIsGoogleAdsConnected] = useState(false);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true)
  const detailsRef = useRef(null)
  
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  function downloadData(format){

    const visibleColumns = columns.filter((col) => col.visible);
    const headers = visibleColumns.map((col) => col.title);
    const rows = campaignData.map((item) => visibleColumns.map((col) => {
      let campaignTypeKey = Object.keys(item)[0]
      let campaignObjectValues = Object.values(item)[0]
      if(col.key === "Campaign Type"){
        return campaignTypeKey
      }
      if(col.key === "cost"){
        return numberWithCommas((campaignObjectValues[col.key] / 1000000).toFixed(0))
      }
      
      if(col.key === "ctr"){
        return numberWithCommas((campaignObjectValues[col.key]).toFixed(2)) + "%"
      }
      if(col.key === "leads"){
        return campaignObjectValues['conversion']
      }
      
      if(col.key === 'Total Budget for "this month"'){
        if(item.totalBudget == undefined){
          return String(0)
        }else{
          return String(item.totalBudget)
        }
      }
      
      if(col.key === "Remaining Budget"){
        let campaignStats = item[campaignTypeKey];
        let remainingBudgetTemp = numberWithCommas(campaignStats.remaining_budget.toFixed(0))
        
        if (item.totalBudget != undefined) {
          if (String(item.totalBudget).length > 1) {
            remainingBudgetTemp = numberWithCommas(Number(item.remainingBudget).toFixed(0))
          }
        }
        return remainingBudgetTemp
      }
      // console.log(col, item,campaignObjectValues[col.key]);
      return campaignObjectValues[col.key]
    }));

    // console.log(campaignData);
    // console.log(rows);
    // return null

    if (format === "pdf") {

      
      const doc = new jsPDF();
      autoTable(doc, { head: [headers], body: rows });
      doc.save("data.pdf");
      


    } else if (format === "csv" || format === "excel") {
      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      if (format === "csv") {
        XLSX.writeFile(wb, "data.csv");
      } else {
        XLSX.writeFile(wb, "data.xlsx");
      }
    } else if (format === "xml") {

      let obj = `<root>\n`
      rows.map(i=>{
        obj += `\t<row>\n`
        visibleColumns.map((j,index)=>{
          obj  += `\t\t<${j.title}>${i[index]}</${j.title}>\n`
        })
        obj += `\t</row>\n`
      })
      obj += `</root>\n`
      console.log("row",obj);
      const blob = new Blob([obj], { type: "application/xml" });
      FileSaver.saveAs(blob, "data.xml");
    } else if (format === "google_sheets") {
      const csvContent = [
        headers.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = `https://docs.google.com/spreadsheets/d/your-sheet-id/edit?usp=sharing`;
      window.open(url, "_blank");
      FileSaver.saveAs(blob, "data.csv");
    }

    setShowDownloadOptions(false);

  }


  let d = new Date();
  let month = d.getMonth();
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // //////console.log(month,"MONTHJKKJHg");
  // setSelectedMonth("October")
  const [selectedMonth, setSelectedMonth] = useState(months[month])

  let [columns, setColumns] = useState([
    { title: "Campaign Type", key: "Campaign Type", visible: true, locked: true, category:"Performance" },
    { title: "Spend", key: "cost", visible: true, locked: true, category:"Metrics" },
    { title: `Total Budget for ${selectedMonth}`, key: `Total Budget for "this month"`, visible: true, locked: true, category:"Performance" },
    { title: "Remaining Budget", key: "Remaining Budget", visible: true, category:"Performance" },
    { title: "Avg. Cost", key: "avg_cost", visible: false, category:"Metrics" },
    { title: "Avg. CPC", key: "avg_cpc", visible: false, category:"Metrics" },
    { title: "Avg. CPM", key: "avg_cpm", visible: false, category:"Metrics" },
    { title: "Budget", key: "budget_micros", visible: false, category:"Performance" },
    { title: "Conversion", key: "conversion", visible: false, category:"Metrics" },
    { title: "Cost per Conversion", key: "cost_per_conv", visible: false, category:"Metrics" },
    { title: "Impressions", key: "impressions", visible: true, category:"Metrics" },
    { title: "Leads", key: "leads", visible: true, category:"Metrics" },
    { title: "Interaction Rate", key: "interaction_rate", visible: false, category:"Metrics" },
    { title: "Clicks", key: "clicks", visible: true, category:"Metrics" },
    { title: "CTR", key: "ctr", visible: true, category:"Metrics" },

    { title: "Spend to Date",   key: "spend_to_date", visible: true, category:"Metrics" },
    { title: "Yesterday Spend", key: "yesterday_spend", visible: true, category:"Metrics" },

    
  ]);

  const [expandedCategory, setExpandedCategory] = useState(null);
  const uniqueCategories = Array.from(new Set(columns.map(col => col.category)));

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return; // If the item was dropped outside the list

    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(source.index, 1);
    updatedColumns.splice(destination.index, 0, movedColumn);
    setColumns(updatedColumns);
  };

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [state, setState] = useState([
    {
      startDate: firstDayOfMonth,
      endDate: today,
      key: 'selection',
    },
  ]);
  const navigate = useNavigate();
  const datePickerRef = useRef(null);

  const [isEditing, setIsEditing] = useState({}); // Track editing state for each row

  const [currencySymbol, setCurrencySymbol] = useState("₹ ")

  const [cardTotal, setCardTotal] = useState([])
  const [cardObjectArray, setCardObjectArray] = useState([
    {id:0 ,title:"Clicks",key:"clicks",                    color:"text-[#03A9F4]",visible:true},
    {id:1 ,title:"Cost",key:"costs",                       color:"text-[#4CAF50]",visible:true},
    {id:2 ,title:"Ctr",key:"ctr",                          color:"text-[#FF5722]",visible:true},
    {id:3 ,title:"Conversion",key:"conversions",           color:"text-[#BA68C8]",visible:true},
    {id:4 ,title:"Avg Cost",key:"average_cost",            color:"text-[#03A9F4]",visible:true},
    {id:5 ,title:"Avg Cpc",key:"average_cpc",              color:"text-[#4CAF50]",visible:false},
    {id:6 ,title:"Avg Cpm",key:"average_cpm",              color:"text-[#FF5722]",visible:false},
    {id:7 ,title:"Impressions",key:"impressions",          color:"text-[#BA68C8]",visible:false},
    {id:8 ,title:"Cost/Conv",key:"cost_per_conv",          color:"text-[#03A9F4]",visible:false},
    {id:9 ,title:"Interaction Rate",key:"interaction_rate",color:"text-[#4CAF50]",visible:false},
    {id:10 ,title:"Interactions",key:"interactions",       color:"text-[#FF5722]",visible:false},
    {id:11 ,title:"Budget",key:"remaining_budget",         color:"text-[#BA68C8]",visible:false},
    {id:12 ,title:"Conversion Rate",key:"conversion_rate", color:"text-[#FF5722]",visible:false},
    
  ])

  const [showCardColumns, setShowCardColumns] = useState(false)

  const setCardTotalFunction = (data) => {
    let cardObjArr = cardObjectArray.map((col,index)=>{      
      let total = 0 
      total = data[col.key]
      
      let obj = {
        name:col.title,
        total:total,
        color:col.color,
        key:col.key
      }
      return obj
    })

    let temp = Object.keys(data)

    let mainids = cardObjectArray.map(item=> item.key)

    let customCols = temp.filter(item=>mainids.indexOf(item) == -1)

    let appendedCols = []
    // id:12 ,title:"Conversion Rate",key:"conversion_rate", color:"text-[#FF5722]",visible:true
    customCols.map(item=>{
      let total = data[item]
      let obj = {
        name:item,
        total:total,
        color:"text-[#4CAF50]",
        key:item
      }

      let appCol = {
        id:-1,
        title:item,
        key:item,
        color:"text-[#4CAF50]",
        visible:false
      } 
      appendedCols.push(appCol)
      cardObjArr.push(obj)
    })

    setCardObjectArray([...cardObjectArray,...appendedCols])
    console.log("cc: ",temp,mainids,customCols,cardObjArr,appendedCols,cardObjectArray);
    
    console.log("card total func",cardObjArr);
    setCardTotal(cardObjArr)

  }

  const changeCardCheckbox = (e) => {
    console.log(e.target, e.target.checked,e.target.checked,e.target.getAttribute("data-key"));
    let value = e.target.checked
    let key = e.target.getAttribute("data-key")

    let newCardObjArr = cardObjectArray.map(item=>{
      if(key == item.key){
        item.visible = value
        console.log("key item",item);
      }
      return item
    })

    setCardObjectArray(newCardObjArr)
  }

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  // let newValues = [0,1,2,3]
  let [newValues, setNewValues] = useState([0,1,2,3])
  
  const handleArrowClick = (value) => {
    let firstId = cardObjectArray[0].id
    let lastId = cardObjectArray[cardObjectArray.length -1].id
    let currentVisibleIds = cardObjectArray.filter(col =>col.visible)
    // let lastVisibleId = currentVisibleIds[currentVisibleIds.length - 1]?.id
    // let firstVisibleId = currentVisibleIds[0]?.id

    
    // let newValues = [];
    let newNewValues = newValues
    let lastValue = newNewValues[newValues.length - 1]
    let firstValue = newNewValues[0]

    if(value == "right"){
      if(lastValue < lastId){
          newNewValues.shift()
          newNewValues.push(lastValue + 1)         
        // for (let i = 1; i <= 4; i++) {
        // }
      }
    }else{
      if(firstValue > firstId){
        newNewValues.pop()
        newNewValues.unshift(firstValue - 1)
        // for (let i = 1; i <= 4; i++) {
        //   newNewValues.shift()
        //   newNewValues.push(firstValue - i)         
        // }
      }
    }
    setNewValues(newNewValues)

    // console.log(newValues ,
    //   newNewValues,
    //   firstValue,
    //   lastValue
    //   );

    cardObjectArray.map(item=>{
      if(newValues.indexOf(item.id) != -1){
        // console.log(item)
        item.visible = true
      }else{
        item.visible = false
      }
    })

    forceUpdate()
  }

  var camelSentence = function camelSentence(str) {
    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
    {
        return chr.toUpperCase();
    });
}

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `https://api.confidanto.com/projects/${projectId}`
        );
        setProject(response.data);
        setIsGoogleAdsConnected(response.data.isGoogleAdsConnected);
        //////console.log(response.data);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    fetchProject();
  }, [projectId]);

  useEffect(() => {
    const checkGoogleAdsConnection = async () => {
      try {
        const email = localStorage.getItem("email");
        if (!email || !projectId) {
          console.error("Missing email or project ID", { email, projectId });
          return;
        }
        //////console.log("Checking Google Ads connection with", { email, projectId });

        const response = await axios.post(
          "https://api.confidanto.com/connect-google-ads/get-customer-id",
          {
            email,
            project_id: projectId,
          }
        );

        //////console.log("API Response:", response.data);

        if (Array.isArray(response.data) && response.data.length > 0) {
          const customerData = response.data[0];
          localStorage.setItem("customer_id", customerData.customer_id);

          console.log("customer_id")
          if (customerData.customer_id) {
            setIsGoogleAdsConnected(true);
            //////console.log("Google Ads is connected for this project.");
            //////console.log(customerData.customer_id)
          } else {
            //////console.log("No customer ID found.");
          }
        } else {
          //////console.log("No valid data in the response.");
        }
      } catch (error) {
        console.error("Error fetching customer ID:", error);
        if (error.response && error.response.data) {
          console.error("API response:", error.response.data);
        }
      }
    };

    checkGoogleAdsConnection();
  }, [projectId]);

  const [customColumnsMain, setCustomColumnsMain] = useState([])
  useEffect(() => {
    // Fetching the data from the API

    const fetchData = async () => {      
      const startDate = format(state[0].startDate, "yyyy-MM-dd");
      const endDate = format(state[0].endDate, "yyyy-MM-dd");

      let requestBody = {
        customer_id: localStorage.getItem("customer_id") == null || localStorage.getItem("customer_id") == "Not Connected" ?"4643036315":localStorage.getItem("customer_id"), // Replace with your customer ID
        start_date:startDate,
        end_date:endDate
  
      };
      
      const customerId = localStorage.getItem("customer_id") == null || localStorage.getItem("customer_id") == "Not Connected" ?"4643036315":localStorage.getItem("customer_id"); // Retrieve the dynamic customer ID


      const response = await fetch('https://api.confidanto.com/get-overview-campaign-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody), 
      });
      const data = await response.json();

      //console.log("Fetched data: 0", data); // Log response in console


      setCampaignData(data);
      setData(data);
      // setCardTotalFunction(data)
      setSelectedMonth("October")
      setIsLoading(false)
    };

    const fetchCardTotal = () => {
      const startDate = format(state[0].startDate, "yyyy-MM-dd");
      const endDate = format(state[0].endDate, "yyyy-MM-dd");

      let requestBody = {
        customer_id: localStorage.getItem("customer_id") == null || localStorage.getItem("customer_id") == "Not Connected" ?"4643036315":localStorage.getItem("customer_id"), // Replace with your customer ID
        email:localStorage.getItem("email"),
        start_date:startDate,
        end_date:endDate
  
      };

      axios.post("https://api.confidanto.com/campaign-overall-metrics",requestBody)
      .then(res=>{
        console.log("JKHBGFCDXSZEDRCFGVHBJNKLJHBGVFCDRXESZSXDCFGVHJHGFDSASDFGHJK",res.data);
        setCardTotalFunction(res.data)

      })
      .catch(err=>{
        console.log("Error Card Total Fetch");
      })
    }
    const fetchCustomColumns = () => {
      axios.post("https://api.confidanto.com/custom_columns/get-custom-columns",{email:localStorage.getItem("email")})
      .then(res=>{

        setCustomColumnsMain(res.data)
        console.log("cusomt sciclan c:",res.data);
      })
      .catch(err=>{
        console.log("Custom Columns Fetch Error");
      })
    }
    fetchCustomColumns()
    fetchData();
    fetchCardTotal()
  }, []);

  // Function to calculate remaining budget
  const calculateRemainingBudget = (cost, totalBudget) => {
    //console.log("mskjdshbianjk", cost, totalBudget);
    return (totalBudget - cost).toFixed(2);
  };
  const formatButtonLabel = () => {
    const { startDate, endDate } = state[0];

    // Check if start and end dates are in the same month and year
    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      // Format as 'Nov 1 - 5, 2024'
      return `${format(startDate, 'MMM d')} - ${format(endDate, 'd, yyyy')}`;
    } else {
      // Format as 'Nov 1, 2024 - Dec 5, 2024' if they differ
      return `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`;
    }
  };
  const handleGoogleAdsClick = async (projectId) => {
    localStorage.setItem("project_id", projectId);
    navigate(`/Googledetails/${projectId}`, { state: { step: 2 } });
  };




  const ColumnItem = ({ column, index, toggleVisibility }) => {
    return (
      <div className="flex items-center p-2 mb-1 rounded cursor-pointer bg-white shadow-sm">
        <input
          type="checkbox"
          checked={column.visible}
          onChange={() => toggleVisibility(column.key)}
          className="mr-2"
          disabled={column.locked}
        />
        <span>{column.title}</span>
      </div>
    );
  };

  const handleBudgetEdit = (id, value) => {

    const updatedData = campaignData.map((campaign) => {
      let campaignType = Object.keys(campaign)[0]
      let campaignStats = campaign[campaignType];


      return campaignType === id
        ? {
          ...campaign,
          totalBudget: value,
          remainingBudget: calculateRemainingBudget((campaignStats.cost / 1000000).toFixed(0), value),
        }
        : campaign
    });
    //console.log("updata,", updatedData);
    setCampaignData(updatedData);
  };

  const toggleEditing = (id) => {
    setIsEditing((prev) => ({ ...prev, [id]: !prev[id] }));
  };


  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  if (!project) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100">
        <div className="text-2xl text-blue-600">Loading...</div>
      </div>
    );
  }
  const fetchAdGroupData = async() => {

    
    const fetchCardTotal = () => {
      const startDate = format(state[0].startDate, "yyyy-MM-dd");
      const endDate = format(state[0].endDate, "yyyy-MM-dd");

      let requestBody = {
        customer_id: localStorage.getItem("customer_id") == null || localStorage.getItem("customer_id") == "Not Connected" ?"4643036315":localStorage.getItem("customer_id"), // Replace with your customer ID
        email:localStorage.getItem("email"),
        start_date:startDate,
        end_date:endDate
  
      };

      axios.post("https://api.confidanto.com/campaign-overall-metrics",requestBody)
      .then(res=>{
        console.log("JKHBGFCDXSZEDRCFGVHBJNKLJHBGVFCDRXESZSXDCFGVHJHGFDSASDFGHJK",res.data);
        setCardTotalFunction(res.data)

      })
      .catch(err=>{
        console.log("Error Card Total Fetch");
      })
    }
    fetchCardTotal()


    setIsLoading(true)
    setShowDatePicker(false);


    const startDate = format(state[0].startDate, "yyyy-MM-dd");
    const endDate = format(state[0].endDate, "yyyy-MM-dd");

    let requestBody = {
      // customer_id: 4643036315,
      customer_id: localStorage.getItem("customer_id") == null || localStorage.getItem("customer_id") == "Not Connected" ?"4643036315":localStorage.getItem("customer_id"),

    };
    if (startDate === endDate) {
      // Single date request
      requestBody = { ...requestBody, single_date: startDate };
    } else {
      // Custom date range request
      requestBody = { ...requestBody, start_date: startDate, end_date: endDate };
    }

    const response = await fetch('https://api.confidanto.com/get-overview-campaign-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody), // Replace with your customer ID
      // body: JSON.stringify({ customer_id: 4643036315 }), // Replace with your customer ID
    });
    const data = await response.json();

    //console.log("Fetched data: 1", data); // Log response in console


    setCampaignData(data);
    setData(data);      
    // setCardTotalFunction(data)

    setIsLoading(false)
    .catch((error) => {
      console.error("Error fetching ad group data:", error);
    });

    
  };
  const openColumnsMenu = () => {
    setShowColumnsMenu(true);
    setTableVisible(false);
  };
  const toggleColumnVisibility = (key) => {
    setColumns(
      columns.map((col) =>
        col.key === key && !col.locked ? { ...col, visible: !col.visible } : col
      )
    );
  };
  const applyChanges = () => {
    setShowColumnsMenu(false);
    setTableVisible(true);
  };

  const cancelChanges = () => {
    setShowColumnsMenu(false);
    setTableVisible(true);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const addCustomColumn = () => {
    const { name, selectedColumns, selectedFormula, manualFormula } = customColumn;
  
    if (!name || selectedColumns.length < 1) {
      alert("Please provide a name and select at least one column.");
      return;
    }
  
    const formula = manualFormula || selectedColumns.map((col) => col.key).join(` ${selectedFormula === "sum" ? "+" : "/"} `);
  
    const requestBody = {
      formula,
      customColumn: name,
      email: localStorage.getItem("email"), // Replace with dynamic email if needed
    };
  
    fetch("https://api.confidanto.com/custom_columns/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to create custom column");
        }
        return response.json();
      })
      .then((result) => {
        // Process the API response
        console.log("Custom column created successfully:", result);
  
        // Update the table data to include the new column
        const newColumnData = data.map((row) => {
          const calculatedValue = eval(
            formula.replace(/(\b[a-zA-Z_]+\b)/g, (match) => row[match] || 0)
          );
          return { ...row, [name]: calculatedValue };
        });
  
        setColumns([...columns, { title: name, key: name, visible: true }]);
        setData(newColumnData);
        setShowCustomColumnForm(false);
        setCustomColumn({ name: "", selectedColumns: [], selectedFormula: "", manualFormula: "" });
  
        alert("Custom column added successfully!");
      })
      .catch((error) => {
        console.error("Error creating custom column:", error);
        alert("An error occurred while creating the custom column.");
      });
  };
  const handleColumnSelect = (event, selectedColumns) => {
    setCustomColumn((prev) => ({
      ...prev,
      selectedColumns,
      manualFormula: selectedColumns.map((col) => col.key).join(" + "),
    }));
  };
  const handleCustomColumnChange = (e) => {
    const { name, value } = e.target;
    setCustomColumn((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <>
        {/* <div className="text-left top-0 left-0 pb-2">
          <h1 className="text-xl font-extrabold text-blue-900">
            {project.name}
          </h1>
        </div> */}

        <div className="text-left flex top-0 left-0 pb-2 justify-between">
          <div>
          <h1 className="text-xl font-extrabold text-blue-900 ml-4">
            {project.name}
          </h1>
          </div>
          <div>
            <ProjectInfo />
          </div>
        </div>

        <div className="p-8 bg-gradient-to-r from-gray-50 to-gray-100 min-h-screen">
          <div className="max-w-xl mx-auto ">
            
          </div>

          <div className="relative">


          {isGoogleAdsConnected && 
            <div className="card-container-parent ">

              {/* <div className="button absolute right-0 top-0"> */}

              <div className="upperRow relative flex flex-row justify-between items-center">
                <div className="calendar">
                  <div className="relative m-2" ref={datePickerRef}>
                    <button
                      onClick={toggleDatePicker}
                      className="bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold "
                    >
                      {formatButtonLabel()}
                    </button>
                    {showDatePicker && (
                      <div className="absolute z-10 mt-2 shadow-lg bg-white left-2">
                        <DateRangePicker
                          onChange={(item) => setState([item.selection])}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={state}
                          maxDate={new Date()}
                          direction="horizontal"
                        />
                        <button
                          onClick={fetchAdGroupData}
                          className="w-full mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold"
                        >
                          Apply
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="cardcolumns">
                  <button
                    className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100
                    justify-center
                    "
                    // absolute right-0 top-0
                    onClick={()=>{setShowCardColumns(!showCardColumns)}}
                  >
                    {/* <PiCardsThreeThin  className="ml-5 text-xl" /> Cards */}
                    <PiCardsBold className="ml-5 text-xl"  />
                  </button>

                    {showCardColumns && 
                      <div className="columns bg-white absolute right-0 top-16 z-10">
                        <div className="flex flex-col p-4 rounded-md ">
                          <div>
                            <div className="font-semibold overflow-x-auto mb-2 text-gray-700">
                              Recommended Cards
                            </div>
                            <div className="grid grid-cols-4 ">
                              {cardObjectArray.map(i=>{
                                return (
                                <>
                                  <div className="flex flex-row m-2  space-x-2">
                                    <input type="checkbox" className="p-2" 
                                    checked={i.visible}
                                    onChange={(e)=>{changeCardCheckbox(e)}}
                                    data-key={i.key}
                                    />
                                    <h2>{i.title}</h2>
                                  </div>
                                </>
                                )
                              })}
                            </div>
                          </div>
                          
                          <div className="flex space-x-2 pt-1 mt-2">
                            <button
                              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                              // onClick={applyChanges}
                              onClick={()=>{setShowCardColumns(false)}}
                            >
                              Apply
                            </button>
                            <button
                              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                              // onClick={cancelChanges}
                              onClick={()=>{setShowCardColumns(false)}}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                </div>
              </div>
              {/* </div> */}

              {/* <div className="mb-4 flex flex-row relative z-0"> */}
              <div className="mb-4 grid grid-cols-5 relative z-0">
                {/* <div className="absolute  -top-2  left-0  h-full 
                flex justify-center items-center">

                  {newValues[0] > cardObjectArray[0].id && 

                  <button className=" text-4xl"
                  onClick={()=>{handleArrowClick("left")}}
                  ><FaAngleLeft /></button>
                  }
                </div>

                <div className="absolute -top-2  right-0 h-full 
                flex justify-center items-center">

                  {newValues[newValues.length -1 ] < cardObjectArray[cardObjectArray.length -1].id  &&
                    <button className=" text-4xl"
                    onClick={()=>{handleArrowClick("right")}}
                    ><FaAngleRight /></button>

                  }
                </div> */}
                  {cardTotal.map(item=>{
                    return (
                    <>
                      {cardObjectArray.filter(col=>col.visible).map(col=>{
                        if(col.key == item.key){
                          return( 
                          // <div className="card w-1/4 flex flex-col items-start justify-center mr-2 my-2 p-6 bg-white rounded-md shadow-lg ">
                          <div className="card  flex flex-col items-start justify-center mr-2 my-2 p-6 bg-white rounded-md shadow-lg ">
                              <div className="text m-2 font-bold text-2xl">
                              {item.name}
                              </div>
                              <div className={`value m-2 font-bold text-2xl ` + item.color}>{item.total}</div>
                          </div>
                          )
                        }
                      })}
                    </>
                    )
                  })}
              </div>
            </div>
          }
              
              
            <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg  mb-28" 
            >
              {isGoogleAdsConnected ? (
                <div className="mt-8 w-full ">
                  {/* Google Ads data table */}
                  <div className="flex space-x-2 justify-between">
                    <div></div>
                  {/* 
                    <div className="relative m-2" ref={datePickerRef}>
                      <button
                        onClick={toggleDatePicker}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold "
                      >
                        {formatButtonLabel()}
                      </button>
                      {showDatePicker && (
                        <div className="absolute z-10 mt-2 shadow-lg bg-white left-2">
                          <DateRangePicker
                            onChange={(item) => setState([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={state}
                            maxDate={new Date()}
                            direction="horizontal"
                          />
                          <button
                            onClick={fetchAdGroupData}
                            className="w-full mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg font-semibold"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div> */}
                    <div className="">
                      <div className="relative">
                        <button
                          className="bg-transparent  text-gray-600 px-4 py-2 rounded  hover:bg-slate-100"
                          // onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                        >
                          <IoMdShareAlt className="ml-5 text-2xl" />
                          Share
                        </button>
                        
                        <button
                          className="bg-transparent  text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                          onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                        >
                          <MdOutlineFileDownload className="ml-5 text-2xl" />
                          Download
                        </button>
                        {showDownloadOptions && (
                          <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border border-gray-200">
                            <button
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                              onClick={() => downloadData("pdf")}
                            >
                              PDF
                            </button>
                            <button
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                              onClick={() => downloadData("csv")}
                            >
                              CSV
                            </button>
                            <button
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                              onClick={() => downloadData("excel")}
                            >
                              Excel
                            </button>
                            <button
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                              onClick={() => downloadData("xml")}
                            >
                              XML
                            </button>
                            <button
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                              onClick={() => downloadData("google_sheets")}
                            >
                              Google Sheets
                            </button>
                          </div>
                        )}
                        <button
                          className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                          onClick={openColumnsMenu}
                        >
                          <FaColumns className="ml-5 text-xl" /> Columns
                        </button>
                        {showColumnsMenu && (
                          <div className="absolute right-0 h-screen bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-800 max-w-3xl border border-gray-200">
                            {/* <div className="font-bold mb-0 w-screen max-h-full text-lg text-gray-700 flex">
                              <div className="justify-between flex">
                                <h2 className="mr-11">Modify columns</h2>
                                <button
                                  className="text-blue-400"
                                  onClick={() => setShowCustomColumnForm(true)}
                                >
                                  + Custom column
                                </button>
                              </div>
                            </div> */}

                            {/* Show Custom Column Form if active */}
                            {showCustomColumnForm ? (
                              <Box className="absolute bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-800 max-w-3xl border border-gray-200">
                                <h2 className="font-bold mb-2 text-lg text-gray-700">Create a custom column</h2>

                                <div className="mb-2">
                                  <TextField
                                    label="Column Name"
                                    name="name"
                                    value={customColumn.name}
                                    onChange={handleCustomColumnChange}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </div>

                                <div className="mb-2">
                                  <Autocomplete
                                    multiple
                                    options={columns}
                                    getOptionLabel={(option) => option.title}
                                    value={customColumn.selectedColumns}
                                    onChange={handleColumnSelect}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Select Columns to Include" variant="outlined" />
                                    )}
                                  />
                                </div>

                                <div className="mb-2">
                                  <TextField
                                    select
                                    label="Select Operation"
                                    name="selectedFormula"
                                    value={customColumn.selectedFormula}
                                    onChange={handleCustomColumnChange}
                                    SelectProps={{ native: true }}
                                    fullWidth
                                    variant="outlined"
                                  >
                                    <option value="">Choose an operation</option>
                                    <option value="sum">Sum</option>
                                    <option value="average">Average</option>
                                    <option value="average">agency_fee</option>
                                    {/* Add more operations as needed */}
                                  </TextField>
                                </div>

                                <div className="mb-2">
                                  <TextField
                                    label="Manual Formula (Editable)"
                                    name="manualFormula"
                                    value={customColumn.manualFormula}
                                    onChange={handleCustomColumnChange}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="e.g., Campaign + Clicks"
                                  />
                                </div>

                                <Box display="flex" gap={2} mt={2}>
                                  <Button variant="contained" color="primary" onClick={addCustomColumn}>
                                    Add Column
                                  </Button>
                                  <Button variant="contained" color="secondary" onClick={() => setShowCustomColumnForm(false)}>
                                    Cancel
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <div className="absolute right-0 h-max bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-max max-w-6xl border border-gray-200 overflow-auto mb-48">
                                  <div className="font-bold mb-0 w-screen max-h-full text-lg text-gray-700 flex overflow-auto">
                                    <div className=" justify-between flex items-center border-b-1 w-[1100px] ">
                                      <div className=" flex border-r-1  p-4 w-3/4">
                                        <h2 className="mr-11">Modify columns</h2>
                                        {/* <button
                                          className="text-blue-400"
                                          onClick={() => setShowCustomColumnForm(true)}
                                        >
                                          + Custom column
                                        </button> */}
                                        
                                        <Link to="/custom-column" className="text-blue-400">
                                        + Custom column</Link>

                                      </div>
                                      <div className=" justify-start">
                                        <h2 className="font-bold mb-4">Your columns</h2>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Show Custom Column Form if active */}
                                  
                                  <div className="flex-grow overflow-auto">
                                      <div className="flex ">
                                        <div className="grid grid-rows-2 gap-6 max-h-screen w-3/4 border-r overflow-auto ">
                                          <div className=" space-x-3 space-y-2">
                                            {uniqueCategories.map(category => (
                                              <div key={category}>
                                                <div className=""
                                                  onClick={() => toggleCategory(category)}>
                                                  <span className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between">{category} {expandedCategory === category ? <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                                                    :
                                                    <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />}</span>
                                                </div>
                                                {expandedCategory === category && (
                                                  <div className="grid grid-cols-3">
                                                    {columns.filter(col => col.category === category).map(col => (
                                                      <>
                                                        <ColumnItem
                                                          key={col.key}
                                                          column={col}
                                                          toggleVisibility={toggleColumnVisibility}
                                                          category={col.category}
                                                        />
                                                      </>
                                                    ))}
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                        <div className="w-max p-4">

                                          <p className="text-sm text-gray-500 mb-4">Drag and drop to reorder</p>
                                          <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="columnsList">
                                              {(provided) => (
                                                <div
                                                  {...provided.droppableProps}
                                                  ref={provided.innerRef}
                                                  className="space-y-2 overflow-auto h-48 border rounded-md p-2"
                                                >
                                                  {columns
                                                    .filter((column) => column.visible) // Only show visible columns
                                                    .map((column, index) => (
                                                      <Draggable key={column.id} draggableId={column.id} index={index}>
                                                        {(provided) => (
                                                          <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="flex items-center p-2 bg-gray-100 mb-1 rounded shadow"
                                                          >
                                                            <span className="flex items-center gap-4"><FaGripLines /> {column.title} </span>
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    ))}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </DragDropContext>
                                          <div className="flex items-center mt-4">
                                            <input type="checkbox" id="saveColumnSet" className="mr-2" />
                                            <label htmlFor="saveColumnSet" className="text-sm">Save your column set (name required)</label>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="flex space-x-2 overflow-auto">
                                        <div className="">
                                          <button
                                            className=" bg-blue-500 text-white px-4  py-2 rounded hover:text-blue-600"
                                            onClick={applyChanges}
                                          >
                                            Apply
                                          </button>
                                          <button
                                            className="text-blue-500  px-4 py-2 rounded hover:text-blue-600"
                                            onClick={cancelChanges}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="overflow-x-auto relative">
                    {isLoading ? (
                      <div className="flex w-full justify-center items-center h-40 mt-8">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <table className="min-w-full table-auto border-collapse border border-gray-200">
                        <thead>
                          <tr className="bg-gray-100">
                            {columns.filter(col => col.visible).map((col, index) => (
                              <th key={index} className="border px-4 py-2">{col.title}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>

                          <>
                            {campaignData.map((campaign, index) => {
                              let campaignType = Object.keys(campaign)[0];
                              let campaignStats = campaign[campaignType];

                              let remainingBudgetTemp = currencySymbol + numberWithCommas(campaignStats.remaining_budget.toFixed(0))
                              // if(campaign.remainingBudget != undefined){
                              //console.log("spamsma", campaign.totalBudget);
                              if (campaign.totalBudget != undefined) {
                                if (String(campaign.totalBudget).length > 1) {
                                  remainingBudgetTemp = currencySymbol + numberWithCommas(Number(campaign.remainingBudget).toFixed(0))
                                }
                              }

                              //////console.log("tpyes: ",campaign)
                              return (
                                <tr key={index} className="hover:bg-gray-50">
                                  {columns.filter(col => col.visible).map((col, idx) => (
                                    <td key={idx} className="border px-4 py-2">
                                      {col.key === "Campaign Type" && campaignType}
                                      {col.key === "cost" && currencySymbol + numberWithCommas((campaignStats.cost / 1000000).toFixed(0))}
                                      {col.key === `Total Budget for "this month"` && (isEditing[campaign.id] ? (
                                        <input
                                          type="number"
                                          key={campaign.id}
                                          value={campaign.totalBudget}
                                          onChange={(e) => handleBudgetEdit(campaignType, e.target.value)}
                                          onBlur={() => toggleEditing(campaign.id)}
                                        />
                                      ) : (
                                        <div className="flex justify-center items-center">
                                          {campaign.totalBudget}
                                          <button
                                            onClick={() => toggleEditing(campaign.id)}
                                            className="text-blue-500 ml-1"
                                          >
                                            <FaPencilAlt />
                                          </button>
                                        </div>
                                      ))}
                                      {/* {col.key === "avg_cost" && (campaignStats.avg_cpm / 1000000).toFixed(2)} */}
                                      {/* {col.key === "avg_cpm" && (campaignStats.avg_cpm / 1000000).toFixed(2)} */}



                                      {col.key === "Remaining Budget" && remainingBudgetTemp}
                                      {/* currencySymbol+numberWithCommas((campaignStats.remaining_budget).toFixed(0))} */}

                                      {col.key === "budget_micros" && currencySymbol + numberWithCommas((campaignStats.budget_micros / 1000000).toFixed(0))}

                                      {col.key === "impressions" && numberWithCommas(campaignStats.impressions)}
                                      {col.key === "clicks" && numberWithCommas(campaignStats.clicks)}
                                      {col.key === "leads" && numberWithCommas(campaignStats.conversion)}
                                      {col.key === "ctr" && numberWithCommas((campaignStats.ctr).toFixed(2)) + "%"}

                                      {col.key === "spend_to_date" &&   currencySymbol + numberWithCommas(campaignStats.spend_to_date.toFixed(0))}
                                      {col.key === "yesterday_spend" && currencySymbol + numberWithCommas(campaignStats.yesterday_spend.toFixed(0))}

                                      {customColumn.length > 0 && customColumn.map((customCol, customIdx) => {
                                        // Get values for selected columns
                                        const column1Value = campaignStats[customCol.selectedColumns[0]];
                                        const column2Value = campaignStats[customCol.selectedColumns[1]];

                                        // Calculate based on selected formula
                                        let calculatedValue = 0;
                                        if (customCol.formula === "sum") {
                                          calculatedValue = column1Value + column2Value;
                                        } else if (customCol.formula === "subtract") {
                                          calculatedValue = column1Value - column2Value;
                                        } else if (customCol.formula === "divide") {
                                          calculatedValue = column1Value / column2Value;
                                        } else if (customCol.formula === "multiply") {
                                          calculatedValue = column1Value * column2Value;
                                        }

                                        return (
                                          <td key={customIdx} className="border px-4 py-2">
                                            {numberWithCommas(calculatedValue.toFixed(2))}
                                          </td>
                                        );
                                      })}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </>
                        </tbody>
                      </table>
                    )}
                  </div>
                  
                  <div className="text-center  mt-4 ">
                    <div className="py-5">
                      <AddTable
                      
                      startDate = {format(state[0].startDate, "yyyy-MM-dd")}
                      endDate = {format(state[0].endDate, "yyyy-MM-dd")}
                      
                      />
                    </div>
                  </div>
                  <div className="flex justify-around items-center h-screen p-8 ">
                    <div className="w-1/2 p-4 m-4 bg-gray-100" 
                      ref={detailsRef}
                      >
                      <DonutChart 
                      startDate={format(state[0].startDate, "yyyy-MM-dd")}
                      endDate={format(state[0].endDate, "yyyy-MM-dd")}
                      />
                    </div>
                    <div className="w-1/2 p-4 m-4 bg-gray-100">

                      <Bar 
                      startDate={format(state[0].startDate, "yyyy-MM-dd")}
                      endDate={format(state[0].endDate, "yyyy-MM-dd")}
                      customerId={localStorage.getItem("customer_id")}
                      />

                    </div>
                  </div>
                  <div className="text-center  mt-4 ">
                    {/* <div className="py-5">
                      <AddCampaignTableWeekly />
                    </div> */}
                    <div className="w-full py-5">
                      <AddChart />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <img src={googleAdsImage} alt="Google Ads" className="w-auto h-48" />
                  <p className="text-xl text-blue-600 font-semibold mt-4">Google Ads</p>
                  <button
                    onClick={() => handleGoogleAdsClick(project.id)}
                    className="mt-8 px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition w-60 text-center"
                  >
                    Connect with Google Ads
                  </button>
                </>
              )}
              {/* <div className="absolute left-4 -bottom-0">
                <FiPlusCircle
                  className="text-blue-500 text-4xl cursor-pointer hover:text-blue-700 transition"
                // onClick={addColumnForm}
                />
              </div> */}
              {isChatOpen && <ChatBox />}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ProjectDetails;
