import axios from "axios";
import LoadingAnimation from "../components/LoadingAnimation";
import React, { useState, useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
    FaFilter,
    FaSearch,
    FaGripLines,
    FaColumns,
    FaExpand,
    FaCompress,
  } from "react-icons/fa";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import { IoIosArrowDown } from "react-icons/io";
import { Autocomplete, TextField, Button, Box } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom"; 
import { RiDeleteBin6Line } from "react-icons/ri";


const GoogleAdsMonthlyReporting = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([
        {title:"Month", key:"month", visible:true , category:"Name"},
        {title:"Clicks", key:"clicks", visible:true , category:"Metric"},
        {title:"Conversions", key:"conversions", visible:true , category:"Metric"},
        {title:"Cost", key:"cost", visible:true , category:"Metric"},
        {title:"Average CPC", key:"average_cpc", visible:false , category:"Metric"},
        {title:"CTR", key:"ctr", visible:false , category:"Metric"},
        {title:"Impressions", key:"impressions", visible:false , category:"Metric"},
        {title:"Conversion Rate", key:"conversion_rate", visible:false , category:"Metric"},
        {title:"Cost per Conversion", key:"cost_per_conversion", visible:false , category:"Metric"},
    ])
    const [selectedYear, setSelectedYear] = useState(null); // Year selection
    const [selectedMonths, setSelectedMonths] = useState([]); // Multi-month selection
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showMetrics, setShowMetrics] = useState(false); // Toggle for showing only metrics
    const [monthNames] = useState([
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]);

    function transformData(data) {
        return data.map(entry => ({
            year: entry.year,
            month: monthNames[entry.month - 1],
            ...entry.overview
        }));
    }


    useEffect(() => {
        if (selectedYear && selectedMonths.length > 0) {
            const fetchReportData = async () => {
                try {
                    setLoading(true);
                    const response = await axios.post('https://api.confidanto.com/monthly-overview', {
                        customer_id: "4643036315",
                        months: selectedMonths.map(monthIndex => monthIndex + 1), // Adjust for zero-based index
                        year: selectedYear,
                    });
                    let data =  transformData(response.data.monthly_overviews)
                    console.log("YEARLY DATA: ",response, transformData(response.data.monthly_overviews))
                    setData(data);
                } catch (error) {
                    setError(error.message || "Something went wrong!");
                } finally {
                    setLoading(false);
                }
            };
            fetchReportData();
        }
    }, [selectedYear, selectedMonths]);

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const toggleMonthSelection = (monthIndex) => {
        setSelectedMonths((prevSelectedMonths) => {
            if (prevSelectedMonths.includes(monthIndex)) {
                return prevSelectedMonths.filter((index) => index !== monthIndex);
            } else {
                return [...prevSelectedMonths, monthIndex];
            }
        });
    };

    const toggleShowMetrics = (e) => {
        console.log(e.target.checked) 
        setShowMetrics(!showMetrics);
        if(e.target.checked){
            columns.map(item=>{
                if(item.category != "Metric"){
                    item.visible = false
                }
            })
        }else{
            columns.map(item=>{
                if(item.category != "Metric"){
                    item.visible = true
                }
            })
        }
    };


    // Options 
    // Expand
    const [isFullScreen, setIsFullScreen] = useState(false);    
    const toggleFullScreen = () => {

        setIsFullScreen(!isFullScreen);
    };

    // Download
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);    
    const downloadData = (format) => {
        const visibleColumns = columns.filter((col) => col.visible);
        const headers = visibleColumns.map((col) => col.title);
        const rows = data.map((item) => visibleColumns.map((col) => item[col.key]));

        if (format === "pdf") {
        const doc = new jsPDF();
        autoTable(doc, { head: [headers], body: rows });
        doc.save("data.pdf");
        } else if (format === "csv" || format === "excel") {
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        if (format === "csv") {
            XLSX.writeFile(wb, "data.csv");
        } else {
            XLSX.writeFile(wb, "data.xlsx");
        }
        } else if (format === "xml") {
        const xmlContent = `
            <root>
            ${data
            .map(
                (item) => `
                <row>
                ${visibleColumns
                    .map((col) => `<${col.key}>${item[col.key]}</${col.key}>`)
                    .join("")}
                </row>
            `
            )
            .join("")}
            </root>
        `;
        const blob = new Blob([xmlContent], { type: "application/xml" });
        FileSaver.saveAs(blob, "data.xml");
        } else if (format === "google_sheets") {
        const csvContent = [
            headers.join(","),
            ...rows.map((row) => row.join(",")),
        ].join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = `https://docs.google.com/spreadsheets/d/your-sheet-id/edit?usp=sharing`;
        window.open(url, "_blank");
        FileSaver.saveAs(blob, "data.csv");
        }
            // setShowDownloadOptions(false);
    };

    // Columns
    const [showColumnsMenu, setShowColumnsMenu] = useState(false);
    const uniqueCategories = Array.from(new Set(columns.map(col => col.category)));
    const [expandedCategory, setExpandedCategory] = useState(null);
  const [tableVisible, setTableVisible] = useState(true);


    const toggleCategory = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };
    const toggleColumnVisibility = (key) => {
        setColumns(
        columns.map((col) =>
            col.key === key && !col.locked ? { ...col, visible: !col.visible } : col
        )
        );
    };
    const onDragEnd = (result) => {
      const { source, destination } = result;
      if (!destination) return; // If the item was dropped outside the list
  
      const updatedColumns = Array.from(columns);
      const [movedColumn] = updatedColumns.splice(source.index, 1);
      updatedColumns.splice(destination.index, 0, movedColumn);
      setColumns(updatedColumns);
    };

    const applyChanges = () => {
      setShowColumnsMenu(false);
      setTableVisible(true);
    };
  
    const cancelChanges = () => {
      setShowColumnsMenu(false);
      setTableVisible(true);
    };
    

    const ColumnItem = ({ column, index, toggleVisibility, category }) => {
        return (
        <div className="flex flex-row items-center justify-between  p-2 mb-1 rounded cursor-pointer bg-white shadow-sm hover:bg-slate-100">
            <div className="">
            <input
                type="checkbox"
                checked={column.visible}
                onChange={() => toggleVisibility(column.key)}
                className="mr-2"
                disabled={column.locked}
            />
            <span>{column.title}</span>
            </div>
            {category == "Custom Columns" && <>
            <button 
            // onClick={()=>{deleteCustomColumn(column.id,column.title)}}
            >
            <RiDeleteBin6Line />
            </button>
            </>}
        </div>
        );
    };


    return (
        <div 
            className={` mb-20 mx-4 bg-white ${isFullScreen
                ? "fixed top-0 left-0 w-full h-full z-50 overflow-x-scroll px-4"
                : "mb-16"
                }`}
        >
            <h1 className="text-4xl font-bold mb-4">Monthly Report</h1>

            {/* Year Selection */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Select Year</h2>
                <select
                    value={selectedYear || ""}
                    onChange={handleYearChange}
                    className="border border-gray-300 rounded p-2 w-full"
                >
                    <option value="" disabled>Select a year</option>
                    {Array.from({ length: 5 }, (_, yearOffset) => {
                        const year = new Date().getFullYear() - yearOffset;
                        return <option key={year} value={year}>{year}</option>;
                    })}
                </select>
            </div>

            {/* Month Selection */}
            {selectedYear && (
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Select Months</h2>
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 gap-4">
                        {monthNames.map((month, index) => (
                            <div
                                key={index}
                                className={`p-4 border rounded-lg cursor-pointer text-center 
                                    ${selectedMonths.includes(index) ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700"}
                                    hover:bg-blue-300`}
                                onClick={() => toggleMonthSelection(index)}
                            >
                                {month}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Toggle Show Metrics */}
            <div className="mb-4 flex flex-row justify-between items-center">
                <div>
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={showMetrics}
                            onChange={(e)=>{toggleShowMetrics(e)}}
                            className="mr-2"
                        />
                        Show Only Metrics
                    </label>
                </div>
                <div className="options flex flex-row justify-center items-center">
                    <div className="relative">
                        <button
                            className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                            onClick={()=>{setShowColumnsMenu(!showColumnsMenu)}}
                        >
                        <FaColumns className="ml-5 text-xl" />
                        Columns
                        </button>
                        {showColumnsMenu && (
                            <div className="absolute right-0 h-max bg-white shadow-md rounded p-4 mt-2 z-20 lg:w-max max-w-6xl border border-gray-200 ">
                                <div className="font-bold mb-0 w-screen  text-lg text-gray-700 flex">
                                <div className=" justify-between flex items-center border-b-1 w-[1100px] ">
                                    <div className=" flex border-r-1  p-4 w-3/4">
                                    <h2 className="mr-11">Modify columns</h2>
                                    {/* <button
                                        className="text-blue-400"
                                        onClick={() => setShowCustomColumnForm(true)}
                                    >
                                        + Custom column
                                    </button> */}
                                    
                                    <Link to="/custom-column" className="text-blue-400">
                                    + Custom column</Link>
                                    </div>
                                    <div className=" justify-start">
                                    <h2 className="font-bold mb-4">Your columns</h2>
                                    </div>
                                </div>
                                </div>
                                <>
                                    <div className="flex ">
                                    <div className="grid grid-rows-2 gap-6  w-3/4">
                                        <div className=" space-x-3 space-y-2">
                                        {uniqueCategories.map(category => (
                                            <div key={category}>
                                            <div className=""
                                                onClick={() => toggleCategory(category)}>
                                                <span className="p-2 flex items-center hover:bg-gray-50 cursor-pointer w-full justify-between">{category} {expandedCategory === category ? <IoIosArrowDown className="ml-2 transform rotate-180 transition-transform duration-300 ease-out text-xl" />
                                                :
                                                <IoIosArrowDown className="ml-2 transform rotate-0 transition-transform duration-300 ease-out text-xl" />}</span>
                                            </div>
                                            {expandedCategory === category && (
                                                <div className="grid grid-cols-3">
                                                {columns.filter(col => col.category === category).map(col => (
                                                    <ColumnItem
                                                    key={col.key}
                                                    column={col}
                                                    toggleVisibility={toggleColumnVisibility}
                                                    category={col.category}
                                                    />
                                                ))}
                                                </div>
                                            )}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    <div className="w-max p-4">

                                        <p className="text-sm text-gray-500 mb-4">Drag and drop to reorder</p>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="columnsList">
                                            {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="space-y-2 overflow-auto h-48 border rounded-md p-2"
                                            >
                                                {columns
                                                .filter((column) => column.visible) // Only show visible columns
                                                .map((column, index) => (
                                                    <Draggable key={column.id} draggableId={column.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="flex items-center p-2 bg-gray-100 mb-1 rounded shadow"
                                                        >
                                                        <span className="flex items-center gap-4"><FaGripLines/> {column.title} </span>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                        </DragDropContext>
                                        <div className="flex items-center mt-4">
                                        <input type="checkbox" id="saveColumnSet" className="mr-2" />
                                        <label htmlFor="saveColumnSet" className="text-sm">Save your column set (name required)</label>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="flex space-x-2 ">
                                    {/* <div className="">
                                        <button
                                        className=" bg-blue-500 text-white px-4  py-2 rounded hover:text-blue-600"
                                        onClick={applyChanges}
                                        >
                                        Apply
                                        </button>
                                        <button
                                        className="text-blue-500  px-4 py-2 rounded hover:text-blue-600"
                                        onClick={cancelChanges}
                                        >
                                        Cancel
                                        </button>
                                    </div> */}
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                    <div className="relative">
                        <button
                            className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                            onClick={()=>{setShowDownloadOptions(!showDownloadOptions)}}
                        >
                        <MdOutlineFileDownload className="ml-5 text-2xl" />
                        Download
                        </button>
                        {showDownloadOptions && (
                            <div className="absolute right-0 bg-white shadow-md rounded p-4 mt-2 z-20 border border-gray-200">
                            <button
                                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => downloadData("pdf")}
                            >
                                PDF
                            </button>
                            <button
                                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => downloadData("csv")}
                            >
                                CSV
                            </button>
                            <button
                                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => downloadData("excel")}
                            >
                                Excel
                            </button>
                            <button
                                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => downloadData("xml")}
                            >
                                XML
                            </button>
                            <button
                                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => downloadData("google_sheets")}
                            >
                                Google Sheets
                            </button>
                            </div>
                        )}
                    </div>
                    <div className="relative">
                        <button
                            className="bg-transparent text-gray-600 px-4 py-2 rounded items-center hover:bg-slate-100"
                            onClick={toggleFullScreen}
                        >
                            {isFullScreen ? (
                                <FaCompress className="ml-5 text-xl" />
                            ) : (
                                <FaExpand className="ml-5 text-xl" />
                            )}{" "}
                            {isFullScreen ? "Collapse" : "Expand"}
                        </button>
                    </div>

                </div>
            </div>

            {/* Display Loading Animation */}
            {loading && (
                <div className="flex justify-center items-center">
                    <LoadingAnimation />
                </div>
            )}

            {/* Error Display */}
            {error && <p className="text-center text-red-300">{error}</p>}

            {/* Data Table */}
            {!loading && !error && data.length > 0 && (
                <table className="min-w-full bg-gray-100 border border-gray-200 mt-4 mb-40">
                    <thead>
                        {/* <tr className="bg-gray-200 text-center"> */}
                            {/* <th className="p-2 border-b">Clicks</th> */}
                        <tr className="bg-gray-200 text-center">
                            {
                                columns.filter(col=>col.visible).map(col=>{
                                    return <th className="p-2 border-b" key={col.key}>{col.title}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr key={index} className="text-center"> */}
                        {/* <td className="p-2">{item.overview.clicks || 0}</td> */}
                        {data.map((item,index)=>(
                            <tr className="text-center" key={index}>
                                {columns.filter(col=>col.visible).map(col=>{
                                    return <td className="p-2" key={col.key}>{item[col.key]}</td>
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* No Data */}
            {!loading && !error && data.length === 0 && (
                <p className="text-center text-gray-500">No data available for the selected year/months.</p>
            )}
        </div>
    );
};

export default GoogleAdsMonthlyReporting;
