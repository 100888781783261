import React, { useState, useEffect } from "react";
import axios from "axios";

const SelectProject = ({ onSelectProject }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const email = localStorage.getItem("email");

  const fetchProjects = async () => {

    
    let customerIdsArr = null
    let customerId = "Not Connected"
    try {
      customerIdsArr = await axios.post("https://api.confidanto.com/connect-google-ads/get-customer-project-id", { email: email })
    } catch (e) { console.log(e) }



    try {
      const response = await axios.post(
        "https://api.confidanto.com/projects-data/fetch-project-list",
        { email }
      );

      console.log("Project Data LIst: nav: ", response.data);
      setProjects(response.data);
      const updatedProjects = response.data.map((project) => {
        
        // Customer Ids
        if (customerIdsArr) {
          let customerIdObj = customerIdsArr.data.filter((e) => {
            return e.project_id == project.id
          })
  
  
          if (customerIdObj.length > 0) {
            customerId = customerIdObj[0].customer_id
          } else {
            customerId = "Not Connected"
          }
  
          // console.log("ids: ",project.id,customerIdObj,customerId);
          // console.log(customerIdsArr.data);
  
        }
  
        return {
          ...project,
          customerId: customerId
        };
  
      })
      setProjects(updatedProjects);

      console.log("Updated Projects:",updatedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }



    
  };


  useEffect(async() => {
    await fetchProjects();
  }, [email]);

  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    onSelectProject(projectId); // Call the callback function
    console.log(event.target.value);

    let row = projects.filter(item=>item.id == event.target.value)

    console.log(event.target.value,row[0]);

    if(row[0]){
      if(row[0].customerId != undefined){
        localStorage.setItem("project_id",row[0].id)
        localStorage.setItem("customer_id",row[0].customerId)
        localStorage.setItem("project_name",row[0].name)
        console.log("Reload",localStorage.getItem("project_name"))
        refreshPage()
      }
    }

  };


  
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <select
      // value={selectedProject}
      onChange={handleProjectChange}
      className="border border-blue-100 p-2 rounded text-gray-600"
      // defaultChecked={localStorage.getItem("project_id")}
      // defaultValue={localStorage.getItem("project_name")}
      // value={localStorage.getItem("project_id")}
    >
      <option value="" selected={localStorage.getItem("project_id") == -1}> Project</option>
      {projects.map((project) => (
        <option key={project.id} value={project.id}
          selected={localStorage.getItem("project_id") == project.id}
        >
          {project.name}
        </option>
      ))}
    </select>
  );
};

export default SelectProject;
