import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import GenderChat from "./Charts/GenderChat";
import Agechart from "./Charts/Agechart";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";

const AddChart = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isAudienceSelected, setIsAudienceSelected] = useState(false); // State to control showing the audience component
  const [isGenderInfoSelected, setIsGenderInfoSelected] = useState(false);
  const handleAddTable = () => {
    setShowOptions(!showOptions);
  };
  const fetchGroupNames = async () => {
    try {
      const response = await axios.post(
        "https://api.confidanto.com/campaign-group/fetch-groups",
        {
          email: localStorage.getItem("email"),
          customer_id: localStorage.getItem("customer_id"),
        }
      );
      const groups = response.data.groups.map((group) => ({
        id: group.group_id,
        name: group.group_name,
      }));
      setGroupNames(groups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };
  const handleAudienceOptionClick = () => {
    setIsAudienceSelected(true); // Show the AudienceDataComponent when "Audience" is selected
    setShowOptions(false); // Close dropdown
  };
  const handleGenderInfoOptionClick = () => {
    setIsGenderInfoSelected(true); // Show the AudienceDataComponent when "Audience" is selected
    setShowOptions(false); // Close dropdown
  };
  const handleGroupChange = (event) => {
    setSelectedGroups(event.target.value);
  };
  return (
    <>
      <div className="p-6 bg-gray-50 min-h-fit">
        <button
          onClick={handleAddTable}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded shadow-md"
        >
          Add Chart
        </button>

        {/* Dropdown Menu */}
        {showOptions && (
          <div className="flex flex-col items-start gap-6">
            <FormControl className="w-full">
              <InputLabel>Select Option</InputLabel>
              <Select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="w-full border border-gray-300 rounded-md bg-white"
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                <MenuItem value="group">Group</MenuItem>
                <MenuItem value="age" onClick={handleAudienceOptionClick}>
                  Audience
                </MenuItem>
                <MenuItem value="Gender" onClick={handleGenderInfoOptionClick}>
                  Gender
                </MenuItem>
                <MenuItem value="audience">Gender</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <div className="flex ">
        {isAudienceSelected && (
          <div className="mt-8 mr-20">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-center text-blue-600">
                  Audience Data
                </h2>
              </div>
            </div>
            <Agechart />
          </div>
        )}
        {selectedOption === "group" && (
          <div className="w-full p-6 border border-gray-300 rounded shadow-sm bg-white">
            <h3 className="text-gray-800 font-semibold mb-4">Select Groups</h3>
            <FormControl className="w-full">
              <InputLabel>Groups</InputLabel>
              <Select
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                renderValue={(selected) => selected.join(", ")}
                className="border border-gray-300 rounded-md bg-white"
              >
                {groupNames.map((group) => (
                  <MenuItem key={group.id} value={group.name}>
                    <Checkbox
                      checked={selectedGroups.indexOf(group.name) > -1}
                    />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedGroups.length > 0 && (
              <button
                //   onClick={fetchData}
                className="mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded shadow-md"
                disabled={loading}
              >
                {loading ? "Loading..." : "Create"}
              </button>
            )}
          </div>
        )}
        {isGenderInfoSelected && (
          <div className="mt-8 ml-20">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-center text-blue-600">
                  GenderInfo
                </h2>
              </div>
              <div className="">
                <button
                  className="p-2 text-lg bg-red-600 text-white rounded-sm"
                  onClick=""
                >
                  <IoMdClose />
                </button>
              </div>
            </div>

            {/* Audience Data Table */}
            <GenderChat />
          </div>
        )}
      </div>
    </>
  );
};

export default AddChart;
